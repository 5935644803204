@import "bootstrap/scss/functions";
@import "utilities/icons";

/*
* Logo Svg String
** make sure the fill is set to 'COLORTOUSEASFILL'
** THIS CURRENTLY IS JUST A LOADING ICON
*/
$logo-svg-string: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='COLORTOUSEASFILL' fill-rule='evenodd' d='M12 2.936c-.625 0-1.133.508-1.133 1.133v2.266a1.133 1.133 0 0 0 2.266 0V4.069c0-.625-.508-1.133-1.133-1.133zm0 13.596c-.625 0-1.133.506-1.133 1.133v2.266a1.133 1.133 0 1 0 2.266 0v-2.266c0-.627-.508-1.133-1.133-1.133zm4.806-7.736l1.602-1.603a1.132 1.132 0 1 0-1.602-1.602l-1.602 1.602a1.133 1.133 0 1 0 1.602 1.603zm-9.613 6.408L5.59 16.806a1.132 1.132 0 1 0 1.602 1.602l1.602-1.602a1.132 1.132 0 1 0-1.602-1.602zm0-9.613A1.132 1.132 0 1 0 5.59 7.193l1.602 1.603a1.134 1.134 0 0 0 1.602-1.603L7.193 5.59zm9.613 9.613a1.132 1.132 0 1 0-1.602 1.602l1.602 1.602a1.132 1.132 0 1 0 1.602-1.602l-1.602-1.602zm3.125-4.337h-2.266a1.133 1.133 0 1 0 0 2.266h2.266a1.133 1.133 0 1 0 0-2.266zM7.468 12c0-.625-.508-1.133-1.133-1.133H4.069a1.133 1.133 0 0 0 0 2.266h2.266c.625 0 1.133-.508 1.133-1.133z'%3E%3C/path%3E%3C/svg%3E";


/*
 * COLORS
 */
$brand-primary: #0d0b0a;// Don't forget to update the emailVariables.properties files when these three are set
$brand-secondary: #fff;// Don't forget to update the emailVariables.properties files when these three are set
$brand-tertiary: #8c8a88;// Don't forget to update the emailVariables.properties files when these three are set
$brand-blue: #1a2b56;
$brand-red: #710919;
$brand-green: #013727;
$brand-accent: $brand-red;
$brand-accent-alt: $brand-green;
$brand-primary-active: darken($brand-primary, 10%);
$brand-secondary-active: darken($brand-secondary, 10%);
$brand-tertiary-active: lighten($brand-tertiary, 10%);

$black: #0d0b0a; // Gray 100
$light-gray: #e6e6e6; // Gray 40
$gray: #8f8f8f; // Gray 60
$dark-gray: #595757; // Gray 80
$white: #fff; // Gray 00
$off-white: #fafafa; // Gray 20
$disabled: #716f6e;

$green: #155624;
$red: #a60000;
$yellow: #856404;
$blue: #5a49b2;
$success: $green;
$danger: $red;
$warning: $yellow;
$info: $blue;
$sale: $red;
$focus: #388Bff;

$colors: (
    brand-primary: $brand-primary,
    brand-secondary: $brand-secondary,
    brand-tertiary: $brand-tertiary,
    brand-blue: $brand-blue,
    brand-red: $brand-red,
    brand-green: $brand-green,
    brand-accent: $brand-accent,
    brand-accent-alt: $brand-accent-alt,
    white: $white,
    off-white: $off-white,
    light-gray: $light-gray,
    gray: $gray,
    dark-gray: $dark-gray,
    black: $black,
    green: $green,
    red: $red,
    yellow: $yellow,
    blue: $blue,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info
);

$swatch-colors: (
    'beige': #f5f5dc,
    'black': #000000,
    'blue': #2A56CB,
    'bronze': #997A20,
    'brown': #8B5515,
    'gold': #d4af37,
    'green': #229C44,
    'gray': #8f979d,
    'grey': #8f979d,
    'metallic': #DDE3E4,
    'navy': #362E84,
    'orange':#F2A54A,
    'pink': #CE63B7,
    'purple': #B6A2E5,
    'red': #DB2B35,
    'silver': #DBDBDA,
    'turquoise': #51C8B4,
    'white': $white,
    'yellow': #ECF361
);

$content: (
    primary: $brand-primary,
    secondary: #454343,
    tertiary: #416f6e,
    inverse: $white,
    inverse-special: rgba(255, 255, 255, 0.85),
    accent: $brand-accent,
    accent-alt: $brand-accent-alt,
    disabled: $disabled,
    action-primary: $brand-primary,
    action-primary-hovered: #575757,
    action-primary-pressed: #323232,
    action-secondary: $dark-gray,
    action-secondary-hovered: $brand-tertiary,
    action-secondary-pressed: $brand-tertiary-active,
    success: $success,
    warning: $warning,
    danger: $danger,
    info: $info
);

$bg: (
    primary: $white,
    secondary: #fafafa,
    tertiary: $brand-blue,
    inverse: $brand-primary,
    accent: $brand-accent,
    accent-alt: $brand-accent-alt,
    action-primary: $brand-primary,
    action-primary-hovered: #575757,
    action-primary-pressed: #323232,
    action-secondary: $brand-secondary,
    action-secondary-hovered: $brand-primary,
    action-secondary-pressed: $brand-primary-active,
    disabled: #e4e5e7,
    overlay: rgba(0,0,0,0.4),
    success: #d4edda,
    warning: #fff3cd,
    danger: #f8d7da,
    info: #f4f0ff
);

$border: (
    primary: $brand-primary,
    secondary: #e6e2df,
    tertiary: #f2f1f0,
    inverse: $white,
    action-primary: $brand-primary,
    action-primary-hovered: #575757,
    action-primary-pressed: #323232,
    action-secondary: $dark-gray,
    disabled: #e4e5e7,
    focus: $focus,
    success: #91b499,
    warning: #d7c692,
    danger: #e08a92,
    info: #b4a3ff
);

$color-maps: (
    background: $bg,
    content: $content,
    border: $border
);

/*
* GRID SYSTEM
*/
// These values should match the Breakpoints values in SiteConstants.js and the contentImageBreakpoints site pref
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 1024px,
    xl: 1440px
);

// Containers are set to higher values than corresponding breakpoints to allow them to be full width
$container-max-widths: (
    md: 1420px,
    lg: 1430px,
    xl: 1440px
);

$spacer-sm: 6px;
$spacer: 16px; // This value should match the Spacer value in scripts/constants/SiteConstants.js
$spacer-xl: 24px;
$negative-spacer-sm: -#{$spacer-sm};
$negative-spacer: -#{$spacer};
$negative-spacer-xl: -#{$spacer-xl};

$grid-gutter-widths: (
    xs: $spacer*2,
    md: $spacer*2,
    xl: $spacer-xl*2 // Note: changing this will update all column and container spacing, but will not affect uses of $spacer variable
);


/*
* Z Indexes
*/
$z-index-toast: 1100;
$z-index-modal: 1050;
$z-index-modal-backdrop: 1000;
$z-index-sticky-footer: 500;
$z-index-header-fixed: 400;
$z-index-header-promo-banner: 350;
$z-index-header: 550;
$z-index-refinement-drawer: 250;
$z-index-pdp-sticky-add-to-cart: 200;
$z-index-wishlist-icon: 50;
$z-index-consent-drawer: 2147483601; //This is set so high due to integrations like FreshChat that use z-index of 2147483600


/*
* ASPECT RATIOS
*/
$aspect-ratio-square: calc(1 / 1 * 100%);
$aspect-ratio-landscape: calc(1 / 1.5 * 100%);
$aspect-ratio-portrait: calc(1.5 / 1 * 100%);
$aspect-ratio-one-one: calc(1 / 1 * 100%);
$aspect-ratio-sixteen-nine: calc(9 / 16 * 100%);
$aspect-ratio-nine-sixteen: calc(16 / 9 * 100%);
$aspect-ratio-four-three: calc(3 / 4 * 100%);
$aspect-ratio-three-four: calc(4 / 3 * 100%);
// horizontal versions used for enforcing horizontal width in hero videos
$aspect-ratio-one-one-horizontal: calc(1 / 1 * 100vh);
$aspect-ratio-sixteen-nine-horizontal: calc(16 / 9 * 100vh);
$aspect-ratio-nine-sixteen-horizontal: calc(9 / 16 * 100vh);
$aspect-ratio-four-three-horizontal: calc(4 / 3 * 100vh);
$aspect-ratio-three-four-horizontal: calc(3 / 4 * 100vh);


/*
* MISC
*/
$transition-speed: 200ms; // This value should match the TransitionSpeed value in scripts/constants/SiteConstants.js
$hero-transition-speed-long: 1000ms;
$hero-transition-speed-medium: 600ms;
$hero-transition-speed-short: 200ms;
$base-font-size: 16px;
$base-line-height: 1.43;
$base-font-color: var(--base-font-color, $black);
$border-radius: var(--border-radius, 0px);
$border-color: var(--base-border-color, $light-gray);
$page-background-color: $white;
$leading-lines-border-color: transparent;
$leading-lines-border-width: 0;
$spinner-size: 80px;
$spinner-background-color: $white;


/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
$primary-font: 'Mona Sans', sans-serif;
$secondary-font:  'Mona Sans Wide', sans-serif;
$tertiary-font: 'Mona Sans', sans-serif;
$header-font: $secondary-font;
$body-font: $primary-font;
$variable-settings: 'tnum' off, 'lnum' on, 'cpsp' on;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font,
    mona-sans: $primary-font,
    mona-sans-wide: $secondary-font
);


/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $primary-font;
$default-text-font-size: var(--base-font-size, 16px);
$default-text-line-height: 1.4;
$default-text-font-weight: 400;
$default-text-letter-spacing: normal;

// Paragraphs
$paragraph-font-size: calc(#{$default-text-font-size}); // paragraph size on mobile
$paragraph-line-height: $default-text-line-height;
$paragraph-lg-font-size: calc(#{$default-text-font-size}); // paragraph size on desktop
$paragraph-lg-line-height: $default-text-line-height;
$paragraph-font-size-large: 22px; // large paragraph size on mobile
$paragraph-line-height-large: 1.2;
$paragraph-lg-font-size-large: calc(#{$paragraph-font-size-large} + 2px);  // large paragraph size on desktop
$paragraph-lg-line-height-large: $paragraph-line-height-large;
$paragraph-font-size-small: 12px; // small paragraph size on mobile
$paragraph-line-height-small: 1.2;
$paragraph-lg-font-size-small: $paragraph-font-size-small; // small paragraph size on desktop
$paragraph-lg-line-height-small: $default-text-line-height;
$paragraph-letter-spacing: $default-text-letter-spacing;
$paragraph-bottom-margin: $spacer;

// Links
$link-color: map-get($content, action-primary);
$link-color-hover: map-get($content, action-tertiary);
$link-color-active: map-get($content, action-primary-pressed);
$link-dark-theme-color: map-get($content, inverse);
$link-dark-theme-color-hover: map-get($content, inverse);
$link-font-weight: normal;
$link-text-decoration: underline;
$link-text-decoration-hover: underline;
// BS variable (odd naming convention)
$link-hover-color: $link-color-hover;

//Lists
$list-padding-left: $spacer;
$list-item-bottom-margin: calc(#{$default-text-font-size} / 2);
$list-item-marker-color: $dark-gray;
$list-item-marker-size: 70%;
$ordered-list-marker-font: $secondary-font;
$ordered-list-marker-font-size: $default-text-font-size;
$ordered-list-marker-font-style: normal;
$ordered-list-marker-font-weight: $default-text-font-weight;


/*
* HEADERS
*/
// Mobile header values
$h1-font-family: var(--h1-font, $header-font);
$h1-font-size: var(--h1-mobile-size, 40px);
$h1-line-height: 1;
$h1-font-weight: 600;
$h1-letter-spacing: -0.02em;
$h1-text-transform: none;
$h1-font-style: normal;

$h2-font-family: var(--h2-font, $header-font);
$h2-font-size: var(--h2-mobile-size, 32px);
$h2-line-height: 1;
$h2-font-weight: 600;
$h2-letter-spacing: -0.02em;
$h2-text-transform: none;
$h2-font-style: normal;

$h3-font-family: var(--h3-font, $header-font);
$h3-font-size: var(--h3-mobile-size, 26px);
$h3-line-height: 1;
$h3-font-weight: 600;
$h3-letter-spacing: -0.02em;
$h3-text-transform: none;
$h3-font-style: normal;

$h4-font-family: var(--h4-font, $header-font);
$h4-font-size: var(--h4-mobile-size, 22px);
$h4-line-height: 1.1;
$h4-font-weight: 600;
$h4-letter-spacing: -0.02em;
$h4-text-transform: none;
$h4-font-style: normal;

$h5-font-family: var(--h5-font, $header-font);
$h5-font-size: var(--h5-mobile-size, 18px);
$h5-line-height: 1.1;
$h5-font-weight: 600;
$h5-letter-spacing: -0.02em;
$h5-text-transform: none;
$h5-font-style: normal;

$h6-font-family: var(--h6-font, $header-font);
$h6-font-size: var(--h6-mobile-size, 16px);
$h6-line-height: 1.1;
$h6-font-weight: 600;
$h6-letter-spacing: -0.02em;
$h6-text-transform: none;
$h6-font-style: normal;

$h7-font-family: var(--h7-font, $primary-font);
$h7-font-size: var(--h7-mobile-size, 16px);
$h7-line-height: 22px;
$h7-font-weight: 400;
$h7-letter-spacing: $default-text-letter-spacing;
$h7-text-transform: none;
$h7-font-style: normal;

// Medium desktop header values
$h1-md-font-size: var(--h1-tablet-size, $h1-font-size);
$h1-md-line-height: $h1-line-height;
$h1-md-font-weight: $h1-font-weight;
$h1-md-letter-spacing: $h1-letter-spacing;
$h1-md-text-transform: $h1-text-transform;
$h1-md-font-style: $h1-font-style;

$h2-md-font-size: var(--h2-tablet-size, $h2-font-size);
$h2-md-line-height: $h2-line-height;
$h2-md-font-weight: $h2-font-weight;
$h2-md-letter-spacing: $h2-letter-spacing;
$h2-md-text-transform: $h2-text-transform;
$h2-md-font-style: $h2-font-style;

$h3-md-font-size: var(--h3-tablet-size, $h3-font-size);
$h3-md-line-height: $h3-line-height;
$h3-md-font-weight: $h3-font-weight;
$h3-md-letter-spacing: $h3-letter-spacing;
$h3-md-text-transform: $h3-text-transform;
$h3-md-font-style: $h3-font-style;

$h4-md-font-size: var(--h4-tablet-size, $h4-font-size);
$h4-md-line-height: $h4-line-height;
$h4-md-font-weight: $h4-font-weight;
$h4-md-letter-spacing: $h4-letter-spacing;
$h4-md-text-transform: $h4-text-transform;
$h4-md-font-style: $h4-font-style;

$h5-md-font-size: var(--h5-tablet-size, $h5-font-size);
$h5-md-line-height: $h5-line-height;
$h5-md-font-weight: $h5-font-weight;
$h5-md-letter-spacing: $h5-letter-spacing;
$h5-md-text-transform: $h5-text-transform;
$h5-md-font-style: $h5-font-style;

$h6-md-font-size: var(--h6-tablet-size, $h6-font-size);
$h6-md-line-height: $h6-line-height;
$h6-md-font-weight: $h6-font-weight;
$h6-md-letter-spacing: $h6-letter-spacing;
$h6-md-text-transform: $h6-text-transform;
$h6-md-font-style: $h6-font-style;

$h7-md-font-size: var(--h7-tablet-size, $h7-font-size);
$h7-md-line-height: $h7-line-height;
$h7-md-font-weight: $h7-font-weight;
$h7-md-letter-spacing: $h7-letter-spacing;
$h7-md-text-transform: $h7-text-transform;
$h7-md-font-style: $h7-font-style;

// Large desktop header values
$h1-lg-font-size: var(--h1-desktop-size, 48px);
$h1-lg-line-height: 1;
$h1-lg-font-weight: $h1-font-weight;
$h1-lg-letter-spacing: $h1-letter-spacing;
$h1-lg-text-transform: $h1-text-transform;
$h1-lg-font-style: $h1-font-style;

$h2-lg-font-size: var(--h2-desktop-size, 36px);
$h2-lg-line-height: 1;
$h2-lg-font-weight: $h2-font-weight;
$h2-lg-letter-spacing: $h2-letter-spacing;
$h2-lg-text-transform: $h2-text-transform;
$h2-lg-font-style: $h2-font-style;

$h3-lg-font-size: var(--h3-desktop-size, 28px);
$h3-lg-line-height: 1;
$h3-lg-font-weight: $h3-font-weight;
$h3-lg-letter-spacing: $h3-letter-spacing;
$h3-lg-text-transform: $h3-text-transform;
$h3-lg-font-style: $h3-font-style;

$h4-lg-font-size: var(--h4-desktop-size, 24px);
$h4-lg-line-height: 1.1;
$h4-lg-font-weight: $h4-font-weight;
$h4-lg-letter-spacing: $h4-letter-spacing;
$h4-lg-text-transform: $h4-text-transform;
$h4-lg-font-style: $h4-font-style;

$h5-lg-font-size: var(--h5-desktop-size, 20px);
$h5-lg-line-height: 1.1;
$h5-lg-font-weight: $h5-font-weight;
$h5-lg-letter-spacing: $h5-letter-spacing;
$h5-lg-text-transform: $h5-text-transform;
$h5-lg-font-style: $h5-font-style;

$h6-lg-font-size: var(--h6-desktop-size, 16px);
$h6-lg-line-height: 1.1;
$h6-lg-font-weight: $h6-font-weight;
$h6-lg-letter-spacing: $h6-letter-spacing;
$h6-lg-text-transform: $h6-text-transform;
$h6-lg-font-style: $h6-font-style;

$h7-lg-font-size: var(--h6-desktop-size, 16px);
$h7-lg-line-height: 1.1;
$h7-lg-font-weight: $h7-font-weight;
$h7-lg-letter-spacing: $h7-letter-spacing;
$h7-lg-text-transform: $h7-text-transform;
$h7-lg-font-style: $h7-font-style;

$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-font-weight $h1-letter-spacing $h1-text-transform $h1-font-style $h1-md-font-size $h1-md-line-height
        $h1-md-font-weight $h1-md-letter-spacing $h1-md-text-transform $h1-md-font-style $h1-lg-font-size $h1-lg-line-height
        $h1-lg-font-weight $h1-lg-letter-spacing $h1-lg-text-transform $h1-lg-font-style,
    h2 $h2-font-family $h2-font-size $h1-line-height $h2-font-weight $h2-letter-spacing $h2-text-transform $h2-font-style $h2-md-font-size $h2-md-line-height
        $h2-md-font-weight $h2-md-letter-spacing $h2-md-text-transform $h2-md-font-style $h2-lg-font-size $h2-lg-line-height
        $h2-lg-font-weight $h2-lg-letter-spacing $h2-lg-text-transform $h2-lg-font-style,
    h3 $h3-font-family $h3-font-size $h3-line-height $h3-font-weight $h3-letter-spacing $h3-text-transform $h3-font-style $h3-md-font-size $h3-md-line-height
        $h3-md-font-weight $h3-md-letter-spacing $h3-md-text-transform $h3-md-font-style $h3-lg-font-size $h3-lg-line-height
        $h3-lg-font-weight $h3-lg-letter-spacing $h3-lg-text-transform $h3-lg-font-style,
    h4 $h4-font-family $h4-font-size $h4-line-height $h4-font-weight $h4-letter-spacing $h4-text-transform $h4-font-style $h4-md-font-size $h4-md-line-height
        $h4-md-font-weight $h4-md-letter-spacing $h4-md-text-transform $h4-md-font-style $h4-lg-font-size $h4-lg-line-height
        $h4-lg-font-weight $h4-lg-letter-spacing $h4-lg-text-transform $h4-lg-font-style,
    h5 $h5-font-family $h5-font-size $h5-line-height $h5-font-weight $h5-letter-spacing $h5-text-transform $h5-font-style $h5-md-font-size $h5-md-line-height
        $h5-md-font-weight $h5-md-letter-spacing $h5-md-text-transform $h5-md-font-style $h5-lg-font-size $h5-lg-line-height
        $h5-lg-font-weight $h5-lg-letter-spacing $h5-lg-text-transform $h5-lg-font-style,
    h6 $h6-font-family $h6-font-size $h6-line-height $h6-font-weight $h6-letter-spacing $h6-text-transform $h6-font-style $h6-md-font-size $h6-md-line-height
        $h6-md-font-weight $h6-md-letter-spacing $h6-md-text-transform $h6-md-font-style $h6-lg-font-size $h6-lg-line-height
        $h6-lg-font-weight $h6-lg-letter-spacing $h6-lg-text-transform $h6-lg-font-style,
    h7 $h7-font-family $h7-font-size $h7-line-height $h7-font-weight $h7-letter-spacing $h7-text-transform $h7-font-style $h7-md-font-size $h7-md-line-height
        $h7-md-font-weight $h7-md-letter-spacing $h7-md-text-transform $h7-md-font-style $h7-lg-font-size $h7-lg-line-height
        $h7-lg-font-weight $h7-lg-letter-spacing $h7-lg-text-transform $h7-lg-font-style
);


/*
* BANNER STYLES
*/
$b1-font-family: var(--b1-font, $header-font);
$b1-font-size: var(--b1-mobile-size, 38px);
$b1-line-height: 38px;
$b1-font-weight: 700;
$b1-letter-spacing: 0.03em;
$b1-text-transform: uppercase;
$b1-font-style: normal;

$b2-font-family: var(--b2-font, $header-font);
$b2-font-size: var(--b2-mobile-size, 34px);
$b2-line-height: 34px;
$b2-font-weight: 700;
$b2-letter-spacing: -0.02em;
$b2-text-transform: none;
$b2-font-style: normal;

$b3-font-family: var(--b3-font, $header-font);
$b3-font-size: var(--b3-mobile-size, 28px);
$b3-line-height: 28px;
$b3-font-weight: 700;
$b3-letter-spacing: -0.02em;
$b3-text-transform: none;
$b3-font-style: normal;

$b4-font-family: var(--b4-font, $primary-font);
$b4-font-size: var(--b4-mobile-size, 12px);
$b4-line-height: 12px;
$b4-font-weight: 600;
$b4-letter-spacing: 0.1em;
$b4-text-transform: uppercase;
$b4-font-style: normal;

$b5-font-family: var(--b5-font, $primary-font);
$b5-font-size: var(--b5-mobile-size, 11px);
$b5-line-height: 110%;
$b5-font-weight: 600;
$b5-letter-spacing: 0.1em;
$b5-text-transform: uppercase;
$b5-font-style: normal;

// Medium Banner Breakpoint values
$b1-md-font-family: $b1-font-family;
$b1-md-font-size: var(--b1-tablet-size, $b1-font-size);
$b1-md-line-height: $b1-line-height;
$b1-md-font-weight: $b1-font-weight;
$b1-md-letter-spacing: $b1-letter-spacing;
$b1-md-text-transform: $b1-text-transform;
$b1-md-font-style: $b1-font-style;

$b2-md-font-family: $b2-font-family;
$b2-md-font-size: var(--b2-tablet-size, $b2-font-size);
$b2-md-line-height: $b2-line-height;
$b2-md-font-weight: $b2-font-weight;
$b2-md-letter-spacing: $b2-letter-spacing;
$b2-md-text-transform: $b2-text-transform;
$b2-md-font-style: $b2-font-style;

$b3-md-font-family: $b3-font-family;
$b3-md-font-size: var(--b3-tablet-size, $b3-font-size);
$b3-md-line-height: $b3-line-height;
$b3-md-font-weight: $b3-font-weight;
$b3-md-letter-spacing: $b3-letter-spacing;
$b3-md-text-transform: $b3-text-transform;
$b3-md-font-style: $b3-font-style;

$b4-md-font-family: $b4-font-family;
$b4-md-font-size: var(--b4-tablet-size, $b4-font-size);
$b4-md-line-height: $b4-line-height;
$b4-md-font-weight: $b4-font-weight;
$b4-md-letter-spacing: $b4-letter-spacing;
$b4-md-text-transform: $b4-text-transform;
$b4-md-font-style: $b4-font-style;

$b5-md-font-family: $b5-font-family;
$b5-md-font-size: var(--b5-tablet-size, $b5-font-size);
$b5-md-line-height: $b5-line-height;
$b5-md-font-weight: $b5-font-weight;
$b5-md-letter-spacing: $b4-letter-spacing;
$b5-md-text-transform: $b5-text-transform;
$b5-md-font-style: $b5-font-style;

// Large desktop banner values
$b1-lg-font-family: $b1-font-family;
$b1-lg-font-size: var(--b1-desktop-size, 44px);
$b1-lg-line-height: 44px;
$b1-lg-font-weight: $b1-font-weight;
$b1-lg-letter-spacing: $b1-letter-spacing;
$b1-lg-text-transform: $b1-text-transform;
$b1-lg-font-style: $b1-font-style;

$b2-lg-font-family: $b2-font-family;
$b2-lg-font-size: var(--b2-desktop-size, 42px);
$b2-lg-line-height: $b2-line-height;
$b2-lg-font-weight: $b2-font-weight;
$b2-lg-letter-spacing: $b2-letter-spacing;
$b2-lg-text-transform: $b2-text-transform;
$b2-lg-font-style: $b2-font-style;

$b3-lg-font-family: $b3-font-family;
$b3-lg-font-size: var(--b3-desktop-size, 32px);
$b3-lg-line-height: $b3-line-height;
$b3-lg-font-weight: $b3-font-weight;
$b3-lg-letter-spacing: $b3-letter-spacing;
$b3-lg-text-transform: $b3-text-transform;
$b3-lg-font-style: $b3-font-style;

$b4-lg-font-family: $b4-font-family;
$b4-lg-font-size: var(--b4-desktop-size, 13px);
$b4-lg-line-height: $b4-line-height;
$b4-lg-font-weight: $b4-font-weight;
$b4-lg-letter-spacing: $b4-letter-spacing;
$b4-lg-text-transform: $b4-text-transform;
$b4-lg-font-style: $b4-font-style;

$b5-lg-font-family: $b5-font-family;
$b5-lg-font-size: var(--b5-desktop-size, 12px);
$b5-lg-line-height: $b5-line-height;
$b5-lg-font-weight: $b5-font-weight;
$b5-lg-letter-spacing: $b5-letter-spacing;
$b5-lg-text-transform: $b5-text-transform;
$b5-lg-font-style: $b5-font-style;

$banners: (
    b1 $b1-font-family $b1-font-size $b1-line-height $b1-font-weight $b1-letter-spacing $b1-text-transform $b1-font-style $b1-md-font-size $b1-md-line-height
        $b1-md-font-weight $b1-md-letter-spacing $b1-md-text-transform $b1-md-font-style $b1-lg-font-size $b1-lg-line-height
        $b1-lg-font-weight $b1-lg-letter-spacing $b1-lg-text-transform $b1-lg-font-style,
    b2 $b2-font-family $b2-font-size $b2-line-height $b2-font-weight $b2-letter-spacing $b2-text-transform $b2-font-style $b2-md-font-size $b2-md-line-height
        $b2-md-font-weight $b2-md-letter-spacing $b2-md-text-transform $b2-md-font-style $b2-lg-font-size $b2-lg-line-height
        $b2-lg-font-weight $b2-lg-letter-spacing $b2-lg-text-transform $b2-lg-font-style,
    b3 $b3-font-family $b3-font-size $b3-line-height $b3-font-weight $b3-letter-spacing $b3-text-transform $b3-font-style $b3-md-font-size $b3-md-line-height
        $b3-md-font-weight $b3-md-letter-spacing $b3-md-text-transform $b3-md-font-style $b3-lg-font-size $b3-lg-line-height
        $b3-lg-font-weight $b3-lg-letter-spacing $b3-lg-text-transform $b3-lg-font-style,
    b4 $b4-font-family $b4-font-size $b4-line-height $b4-font-weight $b4-letter-spacing $b4-text-transform $b4-font-style $b4-md-font-size $b4-md-line-height
        $b4-md-font-weight $b4-md-letter-spacing $b4-md-text-transform $b4-md-font-style $b4-lg-font-size $b4-lg-line-height
        $b4-lg-font-weight $b4-lg-letter-spacing $b4-lg-text-transform $b4-lg-font-style,
    b5 $b5-font-family $b5-font-size $b5-line-height $b5-font-weight $b5-letter-spacing $b5-text-transform $b5-font-style $b5-md-font-size $b5-md-line-height
        $b5-md-font-weight $b5-md-letter-spacing $b5-md-text-transform $b5-md-font-style $b5-lg-font-size $b5-lg-line-height
        $b5-lg-font-weight $b5-lg-letter-spacing $b5-lg-text-transform $b5-lg-font-style
);

/*
* NAVIGATION STYLES
*/
$n1-font-family: var(--n1-font, var(--primary-font));
$n1-font-size: var(--n1-mobile-size, 18px);
$n1-line-height: 22px;
$n1-font-weight: 600;
$n1-letter-spacing: normal;
$n1-text-transform: none;
$n1-font-style: normal;

$n2-font-family: var(--n2-font, var(--primary-font));
$n2-font-size: var(--n2-mobile-size, 12px);
$n2-line-height: 12px;
$n2-font-weight: 600;
$n2-letter-spacing: 0.1em;
$n2-text-transform: uppercase;
$n2-font-style: normal;

$n3-font-family: var(--n3-font, var(--primary-font));
$n3-font-size: var(--n3-mobile-size, 16px);
$n3-line-height: 19px;
$n3-font-weight: 400;
$n3-letter-spacing: normal;
$n3-text-transform: none;
$n3-font-style: normal;

$n4-font-family: var(--n4-font, var(--primary-font));
$n4-font-size: var(--n4-mobile-size, 16px);
$n4-line-height: 19px;
$n4-font-weight: 600;
$n4-letter-spacing: normal;
$n4-text-transform: none;
$n4-font-style: normal;

// Large desktop banner values
$n1-lg-font-family: $n1-font-family;
$n1-lg-font-size: var(--n1-desktop-size, 13px);
$n1-lg-line-height: 13px;
$n1-lg-font-weight: $n1-font-weight;
$n1-lg-letter-spacing: 0.1em;
$n1-lg-text-transform: uppercase;
$n1-lg-font-style: $n1-font-style;

$n2-lg-font-family: $n2-font-family;
$n2-lg-font-size: var(--n2-desktop-size, 14px);
$n2-lg-line-height: 14px;
$n2-lg-font-weight: 400;
$n2-lg-letter-spacing: $n2-letter-spacing;
$n2-lg-text-transform: none;
$n2-lg-font-style: $n2-font-style;

$n3-lg-font-family: $n3-font-family;
$n3-lg-font-size: var(--n3-desktop-size, 14px);
$n3-lg-line-height: 17px;
$n3-lg-font-weight: $n3-font-weight;
$n3-lg-letter-spacing: $n3-letter-spacing;
$n3-lg-text-transform: $n3-text-transform;
$n3-lg-font-style: $n3-font-style;

$n4-lg-font-family: $n4-font-family;
$n4-lg-font-size: var(--n4-desktop-size, 14px);
$n4-lg-line-height: 17px;
$n4-lg-font-weight: $n4-font-weight;
$n4-lg-letter-spacing: $n4-letter-spacing;
$n4-lg-text-transform: $n4-text-transform;
$n4-lg-font-style: $n4-font-style;

$navigation: (
    n1 $n1-font-family $n1-font-size $n1-line-height $n1-font-weight $n1-letter-spacing $n1-text-transform $n1-font-style $n1-lg-font-size $n1-lg-line-height
        $n1-lg-font-weight $n1-lg-letter-spacing $n1-lg-text-transform $n1-lg-font-style,
    n2 $n2-font-family $n2-font-size $n2-line-height $n2-font-weight $n2-letter-spacing $n2-text-transform $n2-font-style $n2-lg-font-size $n2-lg-line-height
        $n2-lg-font-weight $n2-lg-letter-spacing $n2-lg-text-transform $n2-lg-font-style,
    n3 $n3-font-family $n3-font-size $n3-line-height $n3-font-weight $n3-letter-spacing $n3-text-transform $n3-font-style $n3-lg-font-size $n3-lg-line-height
        $n3-lg-font-weight $n3-lg-letter-spacing $n3-lg-text-transform $n3-lg-font-style,
    n4 $n4-font-family $n4-font-size $n4-line-height $n4-font-weight $n4-letter-spacing $n4-text-transform $n4-font-style $n4-lg-font-size $n4-lg-line-height
        $n4-lg-font-weight $n4-lg-letter-spacing $n4-lg-text-transform $n4-lg-font-style
);


/*
* BUTTONS
*/
$button-font: var(--button-font, $secondary-font);
$button-font-size: var(--button-font-size, 14px);
$button-line-height: 14px;
$button-font-weight: var(--button-font-weight, 700);
$button-padding-top: var(--button-padding-y, 12px);
$button-padding-bottom: var(--button-padding-y, 11px);
$button-padding-left: var(--button-padding-x, 32px);
$button-padding-right: var(--button-padding-x, $button-padding-left);
$button-letter-spacing: 0.1em;
$button-text-transform: var(--button-text-transform, uppercase);
$button-border-radius: var(--button-border-radius, $border-radius);
$button-border-width: 1px;
$button-icon-size: 20px;
$button-text-align: center;
$button-disabled-background: map-get($bg, disabled);
$button-disabled-content: map-get($content, disabled);

// Primary button
$button-primary-font: $button-font;
$button-primary-font-weight: $button-font-weight;
$button-primary-text-transform: $button-text-transform;
$button-primary-color:  map-get($content, inverse);
$button-primary-color-hover: map-get($content, inverse);
$button-primary-dark-theme-color: map-get($content, inverse);
$button-primary-dark-theme-color-hover: map-get($content, inverse);
$button-primary-background:  map-get($bg, action-primary);
$button-primary-background-hover: map-get($bg, action-primary-hovered);
$button-primary-background-active: map-get($bg, action-primary-pressed);
$button-primary-dark-theme-background: map-get($bg, action-primary);
$button-primary-dark-theme-background-hover: map-get($bg, action-primary-hovered);
$button-primary-border-radius: $button-border-radius;
$button-primary-border-width: $button-border-width;
$button-primary-border-color: transparent;
$button-primary-border-color-hover: transparent;
$button-primary-border-color-active: transparent;
$button-primary-dark-theme-border-color: transparent;
$button-primary-dark-theme-border-color-hover: transparent;
$button-primary-text-decoration: none;
$button-primary-text-decoration-hover: none;
$button-primary-padding-top: $button-padding-top;
$button-primary-padding-bottom: $button-padding-bottom;
$button-primary-padding-left: $button-padding-left;
$button-primary-padding-right: $button-padding-right;
$button-primary-text-align: $button-text-align;

// Secondary button
$button-secondary-font: $button-font;
$button-secondary-font-weight: $button-font-weight;
$button-secondary-text-transform: $button-text-transform;
$button-secondary-color: map-get($content, action-primary);
$button-secondary-color-hover: map-get($content, action-primary);
$button-secondary-dark-theme-color: map-get($content, action-primary);
$button-secondary-dark-theme-color-hover: map-get($content, action-primary);
$button-secondary-background: map-get($content, inverse);
$button-secondary-background-hover: map-get($content, inverse-special);
$button-secondary-background-active: map-get($content, inverse);
$button-secondary-dark-theme-background: map-get($content, inverse);
$button-secondary-dark-theme-background-hover: map-get($content, inverse-special);
$button-secondary-border-radius: $button-border-radius;
$button-secondary-border-width: $button-border-width;
$button-secondary-border-color: transparent;
$button-secondary-border-color-hover: map-get($border, action-secondary-hovered);
$button-secondary-border-color-active: map-get($border, action-secondary-pressed);
$button-secondary-dark-theme-border-color: transparent;
$button-secondary-dark-theme-border-color-hover: map-get($border, action-secondary-hovered);
$button-secondary-text-decoration: none;
$button-secondary-text-decoration-hover: none;
$button-secondary-padding-top: $button-padding-top;
$button-secondary-padding-bottom: $button-padding-bottom;
$button-secondary-padding-left: $button-padding-left;
$button-secondary-padding-right: $button-padding-right;
$button-secondary-text-align: $button-text-align;

// Secondary button (special)
$button-special-font: $button-font;
$button-special-font-weight: $button-font-weight;
$button-special-text-transform: $button-text-transform;
$button-special-color: map-get($content, action-primary);
$button-special-color-hover: map-get($content, action-primary);
$button-special-dark-theme-color: map-get($content, action-primary);
$button-special-dark-theme-color-hover: map-get($content, action-primary);
$button-special-background:  map-get($content, inverse-special);
$button-special-background-hover: map-get($content, inverse);
$button-special-background-active: map-get($content, inverse-special);
$button-special-dark-theme-background: map-get($content, inverse-special);
$button-special-dark-theme-background-hover: map-get($content, inverse);
$button-special-border-radius: $button-border-radius;
$button-special-border-width: $button-border-width;
$button-special-border-color: transparent;
$button-special-border-color-hover: map-get($border, action-secondary-hovered);
$button-special-border-color-active: map-get($border, action-secondary-pressed);
$button-special-dark-theme-border-color: transparent;
$button-special-dark-theme-border-color-hover: map-get($border, action-secondary-hovered);
$button-special-text-decoration: none;
$button-special-text-decoration-hover: none;
$button-special-padding-top: $button-padding-top;
$button-special-padding-bottom: $button-padding-bottom;
$button-special-padding-left: $button-padding-left;
$button-special-padding-right: $button-padding-right;
$button-special-text-align: $button-text-align;

// Tertiary Button
$button-tertiary-font: $button-font;
$button-tertiary-font-weight: $button-font-weight;
$button-tertiary-text-transform: $button-text-transform;
$button-tertiary-color: map-get($content, action-primary);
$button-tertiary-color-hover: map-get($content, action-primary-hover);
$button-tertiary-color-active: map-get($content, action-primary);
$button-tertiary-dark-theme-color: map-get($content, inverse);
$button-tertiary-dark-theme-color-hover: map-get($content, inverse-special);
$button-tertiary-dark-theme-color-active: map-get($content, inverse);
$button-tertiary-background: transparent;
$button-tertiary-background-hover: transparent;
$button-tertiary-border-radius: $button-border-radius;
$button-tertiary-border-width: $button-border-width;
$button-tertiary-border-color: transparent;
$button-tertiary-border-color-hover: transparent;
$button-tertiary-text-decoration: none;
$button-tertiary-text-decoration-hover: $button-tertiary-text-decoration;
$button-tertiary-text-decoration-active: $button-tertiary-text-decoration-hover;
$button-tertiary-text-decoration-hover-color: map-get($content, action-primary-hovered);
$button-tertiary-text-decoration-active-color: map-get($content, action-primary-pressed);
$button-tertiary-text-decoration-thickness: 1px;
$button-tertiary-text-decoration-thickness-hover: $button-tertiary-text-decoration-thickness;
$button-tertiary-text-decoration-thickness-active: $button-tertiary-text-decoration-thickness-hover;
$button-tertiary-text-decoration-position: under;
$button-tertiary-text-decoration-offset: 6px;
$button-tertiary-dark-theme-text-decoration-hover-color: $button-tertiary-text-decoration-hover-color;
$button-tertiary-dark-theme-text-decoration-active-color: $button-tertiary-text-decoration-active-color;
$button-tertiary-dark-theme-text-decoration-thickness: $button-tertiary-text-decoration-thickness;
$button-tertiary-dark-theme-text-decoration-thickness-hover: $button-tertiary-text-decoration-thickness-hover;
$button-tertiary-dark-theme-text-decoration-thickness-active: $button-tertiary-text-decoration-thickness-hover;
$button-tertiary-padding-top: $button-padding-top;
$button-tertiary-padding-bottom: $button-padding-bottom;
$button-tertiary-padding-left: 0px;
$button-tertiary-padding-right: 0px;
$button-tertiary-text-align: $button-text-align;

// Small Button
$button-sm-font-size: 12px;
$button-sm-line-height: $button-sm-font-size;
$button-sm-padding-top: 6px;
$button-sm-padding-bottom: 5px;
$button-sm-padding-left: 16px;
$button-sm-padding-right: $button-sm-padding-left;
$button-sm-letter-spacing: 0.1em;
$button-sm-icon-size: 12px;
$button-sm-primary-padding-top: $button-sm-padding-top;
$button-sm-primary-padding-bottom: $button-sm-padding-bottom;
$button-sm-primary-padding-left: $button-sm-padding-left;
$button-sm-primary-padding-right: $button-sm-padding-right;
$button-sm-secondary-padding-top: $button-sm-padding-top;
$button-sm-secondary-padding-bottom: $button-sm-padding-bottom;
$button-sm-secondary-padding-left: $button-sm-padding-left;
$button-sm-secondary-padding-right: $button-sm-padding-right;
$button-sm-tertiary-padding-top: $button-sm-padding-top;
$button-sm-tertiary-padding-bottom: $button-sm-padding-bottom;
$button-sm-tertiary-padding-left: 0px;
$button-sm-tertiary-padding-right: 0px;

// large Button
$button-lg-font-size: $button-font-size;
$button-lg-line-height: $button-lg-font-size;
$button-lg-padding-top: 18px;
$button-lg-padding-bottom: 17px;
$button-lg-padding-left: 48px;
$button-lg-padding-right: $button-lg-padding-left;
$button-lg-letter-spacing: $button-letter-spacing;
$button-lg-icon-size: $button-icon-size;
$button-lg-primary-padding-top: $button-lg-padding-top;
$button-lg-primary-padding-bottom: $button-lg-padding-bottom;
$button-lg-primary-padding-left: $button-lg-padding-left;
$button-lg-primary-padding-right: $button-lg-padding-right;
$button-lg-secondary-padding-top: $button-lg-padding-top;
$button-lg-secondary-padding-bottom: $button-lg-padding-bottom;
$button-lg-secondary-padding-left: $button-lg-padding-left;
$button-lg-secondary-padding-right: $button-lg-padding-right;
$button-lg-tertiary-padding-top: $button-lg-padding-top;
$button-lg-tertiary-padding-bottom: $button-lg-padding-bottom;
$button-lg-tertiary-padding-left: 0px;
$button-lg-tertiary-padding-right: 0px;


/*
* FORMs
*/
// Form Group
$form-group-margin-top: $spacer-xl;

// Inputs
$input-border-color: map-get($border, secondary);
$input-border-color-focus: map-get($border, focus);
$input-border-color-hover: map-get($border, action-secondary);
$input-background-color: $white;
$input-outline: none;
$input-outline-focus: none;
$input-outline-hover: none;
$input-box-shadow: none;
$input-box-shadow-focus: none;
$input-box-shadow-hover: 0 0 0 0 $brand-secondary;
$input-text-color: $base-font-color;
$input-placeholder-color: map-get($content, secondary);
$input-border-width: 1px;
$input-border-radius: $border-radius;
$input-font: $body-font;
$input-font-size: 16px; // this must be 16px or more to prevent automatic zooming on iPhones
$input-font-size-md: 14px;
$input-font-line-height: 14px;
$input-font-letter-spacing: $default-text-letter-spacing;
$input-padding-top: 13px;
$input-padding-bottom: 13px;
$input-padding-left: 16px;
$input-padding-right: 16px;
$input-height: 40px;

// Small input
$input-sm-font-size: 14px;
$input-sm-font-line-height: $input-sm-font-size;
$input-sm-padding-top: 9px;
$input-sm-padding-bottom: $input-sm-padding-top;
$input-sm-padding-left: 12px;
$input-sm-padding-right: $input-sm-padding-left;
$input-sm-height: 32px;

// Labels
$form-label-color: $base-font-color;
$form-label-font: $primary-font;
$form-label-font-weight: normal;
$form-label-font-size: 14px;
$form-label-line-height: $default-text-line-height;
$form-label-margin-bottom: 6px;
$form-label-text-transform: none;
$form-label-letter-spacing: $default-text-letter-spacing;

// Help text
$form-help-text-color: map-get($content, secondary);
$form-help-text-font-size: 11px;
$form-help-text-line-height: 11px;
$form-help-text-letter-spacing: normal;
$form-help-text-margin-top: 6px;

// Validation
$form-validation-color: map-get($colors, danger);
$form-validation-font-size: 11px;

// Radio buttons and check boxes
$radiocheck-label-font: $form-label-font;
$radiocheck-label-font-weight: $form-label-font-weight;
$radiocheck-label-font-size: $form-label-font-size;
$radiocheck-label-text-transform: none;
$radiocheck-label-letter-spacing: $form-label-letter-spacing;
$radiocheck-background-color: $white;
$radiocheck-background-color-selected: $brand-primary;
$radiocheck-background-color-error: lighten($danger, 55%);
$radiocheck-border-color: map-get($border, secondary);
$radiocheck-border-color-selected: $black;
$radiocheck-border-color-error: $danger;
$radiocheck-icon-color: $white;
$radiocheck-button-size: 20px;
$check-button-border-radius: 0;
$radiocheck-button-margin: 8px; // spacing between icon and text label
$radiocheck-vertical-spacing: 3px; // the margin between items when more than one are listed
$radiobutton-selected-inset-amount: 6px;

// Select dropdowns
$select-icon-size: 16px;
$select-padding-right: $input-padding-right + $select-icon-size + $spacer-sm;
// Small dropdown
$select-sm-icon-size: 16px;
$select-sm-padding-right: $input-sm-padding-right + $select-sm-icon-size + $spacer-sm;

// Quantity stepper
$quantity-stepper-icon-size: 10px;
$quantity-stepper-icon-size-small: 8px;

// Inline Button + Input group
$inline-input-button-border-color: map-get($border, primary);
$inline-input-button-border-color-hover: $black;
$inline-input-button-background-color: transparent;
$inline-input-button-box-shadow: none;
$inline-input-button-box-shadow-hover: none;
$inline-input-button-text-color: $input-text-color;
$inline-input-button-placeholder-color: $input-placeholder-color;
$inline-input-button-border-width: 0 0 2px 0;
$inline-input-button-border-radius: 0;
$inline-input-button-font-size: $input-font-size;
$inline-input-button-font-line-height: $input-font-line-height;
$inline-input-button-font-letter-spacing: $input-font-letter-spacing;
$inline-input-button-padding-top: $input-padding-top;
$inline-input-button-padding-bottom: $input-padding-bottom;
$inline-input-button-padding-left: $input-padding-left;
$inline-input-button-padding-right: $input-padding-right;


/*
* BREADCRUMBS
*/
$breadcrumb-font-size: 12px;
$breadcrumb-line-height: 20px;
$breadcrumb-letter-spacing:  -0.006em;
$breadcrumb-font-weight: 400;
$breadcrumb-text-decoration: none;
$breadcrumb-text-decoration-hover: underline;
$breadcrumb-divider: '/';
$breadcrumb-divider-color: map-get($content, secondary);
$breadcrumb-item-padding: calc(#{$spacer-xl} / 2);
$breadcrumb-item-color: map-get($content, secondary);
$breadcrumb-item-color-hover: map-get($content, action-primary);
$breadcrumb-item-color-active: map-get($content, secondary);
$breadcrumb-item-h1-text-transform: capitalize;

/*
* TOOLTIPS
*/
$tooltip-background-color: map-get($bg, inverse);
$tooltip-text-color: map-get($content, inverse);
$tooltip-font-size: 13px;
$tooltip-border-radius: $border-radius;
$tooltip-box-shadow: 0 2px 6px rgba($black, 0.1);
$tooltip-padding: 8px 16px;
$tooltip-max-width: calc(max(25vw, 200px));
$tooltip-text-align: center;
$tooltip-opacity: 1;
$tooltip-icon: $icon-info;
$tooltip-icon-size: 100%;
$tooltip-icon-color: $brand-primary;
$tooltip-icon-color-hover: $tooltip-icon-color;


/*
* POPOVERS
*/
$popover-font-size: 14px;
$popover-border-color: map-get($border, tertiary);
$popover-box-shadow: $tooltip-box-shadow;
$popover-header-background-color: map-get($bg, primary);
$popover-header-text-color: map-get($content, primary);
$popover-header-padding: $spacer-xl;
$popover-body-background-color: map-get($bg, primary);
$popover-body-padding: $spacer-xl;
$popover-border-radius: $border-radius;
$popover-max-width: 300px;


/*
* MODALS
*/
$modal-backdrop-background-color: rgba($black, 0.6);
$modal-background-color: transparent;
$modal-border: none;
$modal-border-radius: $border-radius;
$modal-box-shadow: 0 2px 6px rgba($black, 0.1);
$modal-max-width: 500px;
$modal-sm-max-width: 300px;
$modal-lg-max-width: 900px;
$modal-xl-max-width: 1140px;
$modal-margins-mobile: 8px auto;
$modal-margins-desktop: $spacer*2 auto;
// Header
$modal-header-padding: 24px;
$modal-header-background-color: $brand-secondary;
$modal-header-text-color: map-get($content, primary);
$modal-header-border-color: transparent;
$modal-header-title-size: 5; // This number should be 1-6, corresponding with h1-h6 headers
$modal-header-close-icon-size: 14px;
// Body
$modal-body-padding: 24px;
$modal-body-background-color: $white;
$modal-body-text-color: $base-font-color;
//Close icon for full-screen modals
$modal-body-icon-close-border-radius: $modal-border-radius;
$modal-body-icon-close-position-top: $spacer-xl;
$modal-body-icon-close-position-right: $spacer-xl;
$modal-body-icon-close-font-size: $spacer-xl;
// Footer
$modal-footer-padding: 0 20px 20px;
$modal-footer-background-color: $white;
$modal-footer-text-color: $base-font-color;
$modal-footer-border-color: transparent;
//Dropdown Modal
$dropdown-modal-content-max-width: $modal-lg-max-width;
$dropdown-modal-background-padding: $spacer;
$dropdown-modal-background-color: $white;
$dropdown-modal-header-background-color: $dropdown-modal-background-color;
$dropdown-modal-transition: all $transition-speed ease-in-out;


/*
* ALERTS (also affects toast messages)
*/
$alert-border-radius: 3px;
$alert-border-width: (1px 1px 1px 1px);  //map of 4 values e.g (1px 1px 1px 1px)
$alert-padding: $spacer 24px;
$alert-success-color: $success;
$alert-success-background: map-get($bg, success);
$alert-success-text: map-get($content, success);
$alert-warning-color: map-get($content, warning);
$alert-warning-background: map-get($bg, success);
$alert-warning-text: map-get($content, warning);
$alert-danger-color: $danger;
$alert-danger-background: map-get($bg, danger);
$alert-danger-text: map-get($content, danger);
$alert-info-color: $info;
$alert-info-background: map-get($bg, info);
$alert-info-text: map-get($content, info);


/*
* TOAST MESSAGES
*/
$toast-padding: $spacer;
$toast-box-shadow: 0 2px 4px rgba($black, 0.4);


/*
* CARDS
*/
$card-border-radius: $border-radius;
$card-border-color: map-get($border, secondary);
$card-header-background: $white;
$card-header-color: $brand-primary;
$card-header-border-color: $card-border-color;
$card-header-padding-top: 17px;
$card-header-padding-right: 20px;
$card-header-padding-bottom: 22px;
$card-header-padding-left: 20px;
$card-header-after-icon-top-position: $card-header-padding-top;
$card-header-h-value: 4;
$card-header-or-banner: banner;
$card-header-font-family: $primary-font;
$card-header-font-size: 12px;
$card-header-line-height: 12px;
$card-header-letter-spacing: .1em;
$card-header-text-transform: uppercase;
$card-header-font-weight: 600;
$card-body-font-size: $default-text-font-size;
$card-body-line-height: $default-text-line-height;
$card-body-letter-spacing: $default-text-letter-spacing;
$card-body-background: $white;
$card-body-border-color: $light-gray;
$card-body-color: $base-font-color;
$card-body-padding-bottom: 20px;
$card-body-padding-left: 20px;
$card-body-padding-right: 20px;
$card-body-padding-top: 0;
$card-no-header-body-padding-top: 20px;
$card-footer-background: $white;
$card-footer-color: $card-header-color;
$card-footer-border-color: $card-header-border-color;
$card-footer-padding: $card-header-padding-top $card-header-padding-right $card-header-padding-bottom $card-header-padding-left;
$card-ghost-opacity: 0.5;
$card-form-value-color: $dark-gray;
$card-header-border-bottom-width: 0;
$card-margin-bottom: $spacer;
$card-wishlist-remove-btn-margin-top: 5px;
$card-wishlist-remove-btn-margin-right: 0px;
$card-wishlist-remove-btn-margin-bottom: 0px;
$card-wishlist-remove-btn-margin-left: 20px;
$card-wishlist-border-bottom: 1px solid $light-gray;
$card-wishlist-margin-bottom: 20px;
$card-wishlist-padding-bottom: 20px;

// 'aside' card variation - used on PLP refinements
$card-aside-header-padding-top: 20px;
$card-aside-header-padding-right: $card-header-padding-right;
$card-aside-header-padding-bottom: 20px;
$card-aside-header-padding-left: 0;
$card-aside-header-font-size: 13px;
$card-aside-header-font-weight: bold;
$card-aside-header-line-height: 18px;
$card-aside-body-padding: 0;
$card-aside-border-width: 1px 0;
$card-aside-border-color: $card-border-color;
$card-aside-body-border: none;
$card-aside-footer-border: none;


/*
* COLLAPSIBLE
*/
$collapsible-header-padding-top: 32px;
$collapsible-header-padding-right: $card-header-padding-right;
$collapsible-header-padding-bottom: 32px;
$collapsible-header-padding-left: 0;
$collapsible-body-padding-bottom: $card-header-padding-bottom;
$collapsible-body-padding-left: $card-header-padding-left;
$collapsible-body-padding-right: $card-header-padding-right;
$collapsible-body-padding-top: $card-body-padding-top;
$collapsible-border-color: transparent;
$collapsible-header-background: $card-header-background;
$collapsible-header-color: $card-header-color;
$collapsible-header-icon-color: $black;
$collapsible-header-icon-size: 12px;
$collapsible-body-background: $card-body-background;
$collapsible-body-color: $card-body-color;
$collapsible-header-icon-expand: $icon-plus;
$collapsible-header-icon-close: $icon-minus;
$collapsible-title-header-value: 5;
$collapsible-title-header-or-banner: 'header';

$collapsible-inverse-border-color: transparent;
$collapsible-inverse-header-background: map-get($bg, inverse);
$collapsible-inverse-header-color: map-get($content, inverse);
$collapsible-inverse-header-icon-color: map-get($content, inverse);
$collapsible-inverse-header-icon-size: 12px;
$collapsible-inverse-body-background: map-get($bg, inverse);
$collapsible-inverse-body-color: map-get($content, inverse);
$collapsible-inverse-header-icon-expand: $icon-expand;
$collapsible-inverse-header-icon-close: $icon-collapse;

/*
* ACCORDION
*/
$accordion-header-text-align: left;
$accordion-header-icon-expand: $collapsible-header-icon-expand;
$accordion-header-icon-close: $collapsible-header-icon-close;
$accordion-header-icon-color: $collapsible-header-icon-color;
$accordion-header-icon-size: $collapsible-header-icon-size;
$accordion-border-color: $collapsible-border-color;
$accordion-header-background: $collapsible-header-background;
$accordion-header-color: $collapsible-header-color;
$accordion-body-border-color: $collapsible-body-background;
$accordion-body-background: $collapsible-body-background;
$accordion-body-color: $collapsible-body-color;

$accordion-inverse-header-text-align: left;
$accordion-inverse-header-icon-expand: $collapsible-inverse-header-icon-expand;
$accordion-inverse-header-icon-close: $collapsible-inverse-header-icon-close;
$accordion-inverse-header-icon-color: $collapsible-inverse-header-icon-color;
$accordion-inverse-header-icon-size: $collapsible-inverse-header-icon-size;
$accordion-inverse-border-color: $collapsible-inverse-border-color;
$accordion-inverse-header-background: $collapsible-inverse-header-background;
$accordion-inverse-header-color: $collapsible-inverse-header-color;
$accordion-inverse-body-border-color: $collapsible-inverse-body-background;
$accordion-inverse-body-background: $collapsible-inverse-body-background;
$accordion-inverse-body-color: $collapsible-inverse-body-color;
/*
* TABS
*/
$tab-font: 14px $secondary-font;
$tab-text-transform: none;
$tab-font-weight: 600;
$tab-letter-spacing: -0.01em;
$tab-border-width: 2px;
$tab-border-radius-top-left: $border-radius;
$tab-border-radius-top-right: $border-radius;
$tab-border-radius-bottom-left: 0;
$tab-border-radius-bottom-right: 0;
$tab-padding-top: 12px;
$tab-padding-right: 0;
$tab-padding-bottom: 12px;
$tab-padding-left: 0;
$tab-text-decoration: none;
$tab-background: transparent;
$tab-color: map-get($border, primary);
$tab-border-color: var(--tab-border-color, transparent);
$tab-border-top: var(--tab-border-top, none) solid $tab-border-color;
$tab-border-right: var(--tab-border-right, none) solid $tab-border-color;
$tab-border-bottom: var(--tab-border-bottom, 2px) solid $tab-border-color;
$tab-border-left: var(--tab-border-left, none) solid $tab-border-color;
// active state
$tab-text-decoration-active: none;
$tab-background-active: transparent;
$tab-color-active: $brand-primary;
$tab-border-color-active: var(--tab-border-color-active, map-get($border, primary));
$tab-border-top-active: var(--tab-border-top-active, none) solid $tab-border-color-active;
$tab-border-right-active: var(--tab-border-right-active, none) solid $tab-border-color-active;
$tab-border-bottom-active: var(--tab-border-bottom-active, 2px) solid $tab-border-color-active;
$tab-border-left-active: var(--tab-border-left-active, none) solid $tab-border-color-active;
// hover state
$tab-text-decoration-hover: none;
$tab-background-hover: transparent;
$tab-color-hover: $brand-primary;
$tab-border-top-hover: none;
$tab-border-right-hover: none;
$tab-border-bottom-hover: $tab-border-bottom-active;
$tab-border-left-hover: none;

// Link style tabs
$link-tab-font: $tab-font;
$link-tab-text-transform: $tab-text-transform;
$link-tab-text-decoration: $link-text-decoration;
$link-tab-font-weight: $link-font-weight;
$link-tab-letter-spacing: $tab-letter-spacing;
$link-tab-color: $link-color;
$link-tab-padding-top: calc(#{$spacer} / 2);
$link-tab-padding-right: $spacer;
$link-tab-padding-bottom: calc(#{$spacer} / 2);
$link-tab-padding-left: $spacer;
$link-tab-container-border-bottom: none;
$link-tab-margin-right: 0;
$link-tab-border-bottom: none;
// hover state
$link-tab-text-transform-hover: $link-tab-text-transform;
$link-tab-text-decoration-hover: $link-text-decoration-hover;
$link-tab-font-weight-hover: $link-tab-font-weight;
$link-tab-color-hover: $link-color-hover;
$link-tab-border-bottom-hover: none;
// active state
$link-tab-text-transform-active: $link-tab-text-transform;
$link-tab-text-decoration-active: none;
$link-tab-font-weight-active: bold;
$link-tab-color-active: $link-color;
$link-tab-border-bottom-active: none;
// Pills style tabs
$pills-tab-font: $tab-font;
$pills-tab-text-transform: $tab-text-transform;
$pills-tab-text-decoration: $tab-text-decoration;
$pills-tab-font-weight: $tab-font-weight;
$pills-tab-letter-spacing: $tab-letter-spacing;
$pills-tab-color: $tab-color;
$pills-tab-background: transparent;
$pills-tab-padding-top: calc(#{$spacer} / 2);
$pills-tab-padding-right: $spacer;
$pills-tab-padding-bottom: calc(#{$spacer} / 2);
$pills-tab-padding-left: $spacer;
$pills-tab-border-radius: 6px;
// hover state
$pills-tab-text-transform-hover: $pills-tab-text-transform;
$pills-tab-text-decoration-hover: $pills-tab-text-decoration;
$pills-tab-font-weight-hover: $pills-tab-font-weight;
$pills-tab-color-hover: $tab-color-hover;
$pills-tab-background-hover: $pills-tab-background;
// active state
$pills-tab-text-transform-active: $pills-tab-text-transform;
$pills-tab-text-decoration-active: $pills-tab-text-decoration;
$pills-tab-font-weight-active: bold;
$pills-tab-color-active: $white;
$pills-tab-background-active: $blue;


/*
* SLIDER
*/
// title
$slider-heading-size: 4; // This number should be 1-6, corresponding with h1-h6 headers
$slider-heading-text-align: center;
// nav buttons
$slider-button-prev-icon: $icon-arrow-left;
$slider-button-next-icon: $icon-arrow-right;
$slider-button-color: map-get($content, primary);
$slider-button-background: transparent;
$slider-button-color-hover: map-get($content, secondary);
$slider-button-background-hover: transparent;
$slider-button-background-controls-position-top: $slider-button-background;
$slider-button-background-hover-controls-position-top: $slider-button-background-hover;
$slider-button-icon-size: 16px;
$slider-button-width: 36px;
// pagination bullets
$slider-bullet-size: 12px;
$slider-bullet-size-active: $slider-bullet-size;
$slider-bullet-margin-top: $spacer-sm * 2;
$slider-bullet-margin-right: $spacer-sm;
$slider-bullet-margin-bottom: $spacer-sm * 2;
$slider-bullet-margin-left: $spacer-sm;
$slider-bullet-background: rgba($black, 0.5);
$slider-bullet-border: none;
$slider-bullet-background-active: $black;
$slider-bullet-border-active: none;
$bullets-outside-size: $slider-bullet-size + $slider-bullet-margin-top + $slider-bullet-margin-bottom;

//pagination bullets - short lines
$slider-short-lines-bullet-background-color: $brand-tertiary;
$slider-short-lines-bullet-active-background-color: $brand-primary;
$slider-short-lines-bullet-size-width: 20px;
$slider-short-lines-bullet-size-height: 2px;
$slider-short-lines-bullet-active-size-height: 4px;

//pagination bullets - long lines
$slider-long-lines-bullet-background-color: map-get($border, secondary);
$slider-long-lines-bullet-active-background-color: map-get($border, primary);
$slider-long-lines-bullet-size-width: 20px;
$slider-long-lines-bullet-size-height: 3px;
$slider-long-lines-bullet-active-size-height: 3px;

// pagination bullets - pause/play button
$slider-bullet-controller-size: 25px;
$slider-bullet-pause-icon-color: $brand-primary;
$slider-bullet-play-icon-color: $light-gray;
$slider-bullet-controller-inset-amount: 0;
$slider-bullet-controller-padding: 10px;
$slider-bullet-with-pagination-padding-right: 5px;

// thumbnail nav
$slider-thumbnail-active-padding: 2px;
$slider-thumbnail-active-border-border-radius: 3px;
$slider-thumbnail-image-border-radius: $slider-thumbnail-active-border-border-radius;
$slider-thumbnail-prev-icon: $slider-button-prev-icon;
$slider-thumbnail-next-icon: $slider-button-next-icon;
$slider-thumbnail-button-color: $black;
$slider-thumbnail-button-color-hover: $black;
$slider-thumbnail-button-background-color: $page-background-color;
$slider-thumbnail-button-background-color-hover: $page-background-color;
$slider-thumbnail-button-size: 36px;
$slider-thumbnail-button-icon-size: 12px;
$slider-thumbnail-border-width: 0px;
$slider-thumbnail-border-color: transparent;
$slider-thumbnail-border-active-width: 1px;
$slider-thumbnail-border-active-color: $black;
$slider-thumbnail-spacing: 18px;
$slider-thumbnail-width: 48px;
$slider-thumbnail-width-md: $slider-thumbnail-width;
$slider-thumbnail-width-lg: $slider-thumbnail-width;
$slider-thumbnail-play-icon-size: 26px;
$slider-thumbnail-flex-alignment: flex-start;

// dots for thumbnails
$slider-thumbnail-dots-border-radius: 50%;

// light theme
$slider-light-button-color: rgba($white, 0.7);
$slider-light-button-background: transparent;
$slider-light-button-color-hover: $white;
$slider-light-button-background-hover: transparent;
$slider-light-bullet-background: rgba($white, 0.5);
$slider-light-bullet-border: none;
$slider-light-bullet-background-active: $white;
$slider-light-bullet-border-active: none;
$slider-light-short-lines-bullet-background-color: rgba($white, 0.5);
$slider-light-short-lines-bullet-active-background-color: $white;
$slider-light-bullet-pause-icon-color: $white;
$slider-light-bullet-play-icon-color: $white;

//Slider Title adjustments
$slider-heading-style: banner; // header or banner
$slider-title-padding-y: 15px;
$slider-title-line-height: 15px; //px based

//Suggested Product slider
$slider-suggested-products-title-heading-style: banner;
$slider-suggested-products-title-heading-size: 4;
$slider-suggested-products-button-padding: 10px;
$slider-suggested-products-title-padding-y: 30px;
$slider-suggested-products-title-line-height: 15px; //px based

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/";


/*
* DROPDOWNS
*/
$dropdown-menu-border: 1px solid map-get($border, secondary);
$dropdown-menu-border-radius: $border-radius;
$dropdown-menu-shadow: 0 2px 2px rgba($black, 0.2);
$dropdown-megamenu-border: none;
$dropdown-icon: $icon-expand;
$dropdown-icon-size: 10px;
$dropdown-item-color: $link-color;
$dropdown-item-border-color: $light-gray;
$dropdown-item-inview-background: $light-gray;
$dropdown-item-inview-color: $link-color-hover;
$dropdown-item-hover-background: $light-gray;
$dropdown-item-hover-color: $link-color-hover;
$dropdown-item-padding: $spacer;
$dropdown-fadein-speed: $transition-speed; // set to zero to disable fade effect
$dropdown-font-size: 14px;


/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
// ELVA N1 (mobile)
$nav-link-font-family: $primary-font;
$nav-link-font-size: 18px;
$nav-link-font-weight: var(--primary-nav-font-weight, 600);
$nav-link-text-transform: var(--primary-nav-text-transform, none);
$nav-link-color: var(--primary-nav-color, $brand-primary);
$nav-link-line-height: 1.2;
$nav-link-letter-spacing: normal;
$nav-link-text-decoration: none;
$nav-link-text-decoration-hover: none;
$nav-link-lg-font-size: 13px;
$nav-link-lg-text-transform: uppercase;
$nav-link-lg-line-height: 1;
$nav-link-lg-letter-spacing: 0.1em;
//N3
$nav-sub-link-font-size: 16px;
$nav-sub-link-lg-font-size: 14px;
//N4
$nav-sub-link-list-font-size: 16px;
$nav-sub-link-list-lg-font-size: 14px;
$nav-sub-link-list-lg-font-weight: 600;

/*
* HEADER
*/
// Promo bar
$header-promo-bar-background: map-get($bg, secondary);
$header-promo-bar-color: map-get($content, primary);
$header-promo-bar-padding-top: calc(min(11px, 2vh));
$header-promo-bar-padding-bottom: $header-promo-bar-padding-top;
$header-promo-bar-font-size: 13px;
$header-promo-bar-link-font-size: $header-promo-bar-font-size;
$header-promo-bar-line-height: $header-promo-bar-font-size;

//Favicon — only used in styleguide
$header-favicon-image: var(--brand-favicon, url('../images/favicons/favicon-32x32.png'));

// Pre-nav header bar
$pre-nav-height: 30px;
$pre-nav-height-md: 40px;
$pre-nav-background: $off-white;
$pre-nav-background-active: $white;
$pre-nav-logo-filter: grayscale(1);
$pre-nav-logo-filter-active: grayscale(0);
$pre-nav-text-color: $dark-gray;
$pre-nav-font-family: $primary-font;
$pre-nav-font-weight: normal;
$pre-nav-font-size: 11px;
$pre-nav-text-transform: uppercase;

// Main header
$header-background: var(--header-background-color, $white);
$header-fixed-shadow: $dropdown-menu-shadow;
$header-height: calc(min(54px, 10vh));
$header-height-md: 66px;
$header-height-lg: 66px;
// these are set at the top of this file
$header-logo-image: '../images/logo.svg';
:root{--brand-logo-desktop: url(#{$header-logo-image});}; //used in styleguide.scss
$header-logo-image-url: url($header-logo-image);
$header-logo-image-sm: var(--brand-logo-mobile, $header-logo-image-url);
$header-logo-image-sm-fixed: $header-logo-image-sm;
$header-logo-image-md: var(--brand-logo-desktop, $header-logo-image-url);
$header-logo-image-md-fixed: $header-logo-image-md;
$header-logo-image-lg: $header-logo-image-md;
$header-logo-image-lg-fixed: $header-logo-image-lg;
$header-logo-width: var(--brand-logo-mobile-size, 100px);
$header-logo-width-md: var(--brand-logo-desktop-size, 100px);
$header-logo-width-lg: $header-logo-width-md;
$header-logo-padding-y: var(--header-logo-padding-y, 18px);
$header-logo-height: 100%;
$header-logo-transparent-nav-brightness: 0;
$header-logo-transparent-nav-invert: 1;

$header-transition-speed: 400ms;
$header-link-color: var(--primary-nav-color, $brand-primary);
$header-link-color-hover: var(--primary-nav-color-hover, $header-link-color);
$header-link-color-hover-item-thats-not-hovered: var(--header-link-color-hover-not-hovered, $dark-gray);
$header-link-highlight-color: $red;
$header-link-highlight-color-hover: $brand-secondary;
$header-link-border-color: transparent;
$header-link-font: $nav-link-font-family;
$header-link-font-size: 13px;
$header-link-top-level-font-size: $header-link-font-size;
$header-link-font-weight: $nav-link-font-weight;
$header-link-line-height: $nav-link-line-height;
$header-link-letter-spacing: 0.1em;
// $header-link-underline-position-top: 100%; no longer referenced
$header-link-underline-position-bottom: $spacer;
$header-link-underline-color-inview: $brand-primary; // active state for left-align logo desktop view only, set to transparent to disable
$header-link-underline-height-inview: 1px;
$header-link-underline-color: $brand-primary; // hover state for left-align logo desktop view only, set to transparent to disable
$header-link-underline-height: 1px;
$header-link-text-transform: $nav-link-text-transform;
$header-link-font-size-logo-center: 14px;
$header-search-desktop-padding-right: 40px;
$header-search-desktop-padding-left: 20px;
$header-search-desktop-width: 125px;
$header-search-desktop-width-focus: 200px;
$header-search-desktop-expanded-border-color: $light-gray;
$header-search-desktop-border-width: 0 1px 0 1px;
$header-search-icon-font-size: 16px;
$header-search-icon-color: $header-link-color;
$header-search-icon-color-hover: $brand-secondary;
$header-search-text-font-size: 16px; //minimum of 16px required to prevent zooming on iphones
$header-minicart-count-background: $brand-primary;
$header-minicart-count-color: $white;
$header-minicart-icon-font-size: 16px;
$header-minicart-top-position: -4px;
$header-minicart-right-position: -9px;
$header-link-dropdown-text-align: center; // used for account + support
$header-link-dropdown-menu-border: none;
$header-link-dropdown-menu-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
$header-link-dropdown-menu-padding: 4px;
$header-link-dropdown-menu-font-size: 11px;
$header-link-dropdown-menu-li-padding: 8px;

$header-transparent-background: transparent;
$header-transparent-border-bottom: transparent;
$header-transparent-link-color: transparent;
$header-transparent-link-color-hover: transparent;
$header-transparent-search-desktop-expanded-border-color: transparent;

$header-transparent-text-color-light: $white;
$header-transparent-text-color-dark: $black;
$header-transparent-minicart-count-background-color-light: $black;
$header-transparent-minicart-count-background-color-dark: $red;

// Checkout header
$header-checkout-locale-display: block;
$header-checkout-account-display: flex;
$header-checkout-support-display: block;
$header-checkout-minicart-display: flex;
// Main navbar
$header-navbar-border-bottom-width: 1px;
$header-navbar-border-bottom-color: $border-color;
$header-navbar-link-spacing: 36px; // space between top-level menu items
$header-navbar-dropdown-icon: none; // desktop only - mobile falls back to standard dropdown icon ($dropdown-icon)
$header-navbar-dropdown-icon-size: 10px;
$header-navbar-account-icon: $icon-account;
$header-navbar-account-icon-size: 16px;
$header-navbar-account-icon-size-logged-in: calc(#{$header-navbar-account-icon-size} + 2px); //circles need a bit more space optically to match other shapes
$header-navbar-account-icon-logged-in-background-border-radius: 100%;
$header-navbar-account-letter-font-size: $header-navbar-account-icon-size/1.5;
$header-navbar-account-letter-font-weight: bold;
$header-navbar-account-letter-font-family: $body-font;
$header-navbar-account-font-size: 0; // set to 0 if you only want an icon to show for account desktop only
$header-navbar-account-margin: 8px 0 0;
$header-navbar-background: var(--header-navbar-background-color, $brand-secondary); // center-logo desktop layout only
$header-navbar-color: var(--header-navbar-text-color, $white); // center-logo desktop layout only
$header-navbar-background-hover: var(--header-navbar-background-color-hover, $white); // center-logo desktop layout only
$header-navbar-color-hover: var(--header-navbar-text-color-hover, $header-navbar-background); // center-logo desktop layout only
$header-navbar-background-inview: $header-navbar-background-hover; // center-logo desktop layout only
$header-navbar-color-inview: $header-navbar-color-hover; // center-logo desktop layout only
$header-navbar-toggler-icon-size: 18px; // hamburger menu icon size, mobile only
$header-navbar-highlight-color: $brand-primary; // center-logo desktop layout only
$header-navbar-highlight-background-hover: $white; // center-logo desktop layout only
$header-navbar-highlight-color-hover: $brand-secondary; // center-logo desktop layout only
// Secondary navbar
$header-secondary-nav-background: $header-background;
$header-secondary-nav-link-top-level-font-size: $nav-link-font-size;
$header-secondary-nav-link-top-level-font-family: $nav-link-font-family ;
$header-secondary-nav-link-top-level-font-weight: $nav-link-font-weight;
$header-secondary-nav-link-top-level-line-height: $default-text-line-height;
$header-secondary-nav-link-top-level-letter-spacing: $nav-link-letter-spacing;
$header-secondary-nav-link-top-level-text-transform: $nav-link-text-transform;
$header-secondary-nav-link-top-level-color: $nav-link-color;
$header-secondary-nav-link-top-level-text-decoration: $nav-link-text-decoration;
$header-secondary-nav-link-top-level-text-decoration-hover: $nav-link-text-decoration-hover;
$header-secondary-nav-link-underline-height: $header-link-underline-height;
$header-secondary-nav-link-underline-color: $header-link-underline-color;
$header-secondary-nav-link-underline-position-bottom: $header-link-underline-position-bottom;
$header-secondary-nav-link-highlight-color: $header-link-highlight-color;
$header-secondary-nav-link-highlight-color-hover: $header-link-highlight-color-hover;
$header-secondary-nav-link-underline-height-inview: $header-link-underline-height-inview;
$header-secondary-nav-link-underline-color-inview: $header-link-underline-color-inview;
$header-secondary-navbar-link-spacing: $header-navbar-link-spacing;
$header-secondary-navbar-link-padding-y: 12px;
$header-secondary-navbar-link-padding-x: $header-secondary-navbar-link-spacing/2;
$header-secondary-navbar-link-padding: $header-secondary-navbar-link-padding-y $header-secondary-navbar-link-padding-x;
$header-secondary-navbar-border-bottom-width: $header-navbar-border-bottom-width;
$header-secondary-navbar-border-bottom-color: $header-navbar-border-bottom-color;
$header-secondary-navbar-link-spacing: $header-secondary-navbar-link-spacing; // space between top-level menu items
$header-secondary-navbar-background: hsl(var(--brand-secondary-hue), var(--brand-secondary-saturation), calc(var(--brand-secondary-lightness) + 25%));//$header-navbar-background; // center-logo desktop layout only
$header-secondary-navbar-color: $header-navbar-color; // center-logo desktop layout only
$header-secondary-navbar-background-hover: $header-navbar-background-hover; // center-logo desktop layout only
$header-secondary-navbar-background-inview: $header-navbar-background-inview; // center-logo desktop layout only
$header-secondary-navbar-color-hover: $header-navbar-color-hover; // center-logo desktop layout only
$header-secondary-navbar-color-inview: $header-navbar-color-inview; // center-logo desktop layout only
$header-secondary-navbar-highlight-color: $header-navbar-highlight-color; // center-logo desktop layout only
$header-secondary-navbar-highlight-background-hover: $header-navbar-highlight-background-hover; // center-logo desktop layout only
$header-secondary-navbar-highlight-color-hover: $header-navbar-highlight-color-hover; // center-logo desktop layout only
$header-secondary-nav-transparent-text-color-light: $header-transparent-text-color-light;
$header-secondary-nav-transparent-text-color-dark: $header-transparent-text-color-dark;


// Megamenu
$megamenu-background: $white;
$megamenu-box-shadow: $header-fixed-shadow;
$megamenu-padding-top-bottom: 34px;
$megamenu-padding: $megamenu-padding-top-bottom 0 50px 0;
$megamenu-full-body-padding-top-bottom: 0; //extra padding for everything in megamenu dropdown
$megamenu-full-body-padding-left-right: 95px; //extra padding for everything in megamenu dropdown
$megamenu-category-name-title-header-value: 4;
$megamenu-header-font: map-get($fonts, body);
$megamenu-header-font-size: 14px;
$megamenu-header-line-height: 1.2;
$megamenu-header-font-weight: normal;
$megamenu-header-letter-spacing: -0.005em;
$megamenu-header-text-transform: capitalize;
$megamenu-header-color: var(--secondary-nav-color, $brand-primary);
$megamenu-header-color-hover: var(--secondary-nav-color-hover, $brand-primary);
$megamenu-header-background-hover: transparent;
$megamenu-title-margin-bottom: 30px;
$megamenu-title-text-decoration: none;
$megamenu-secondary-font: $body-font;
$megamenu-secondary-font-size: $nav-sub-link-font-size;
$megamenu-secondary-lg-font-size: $nav-sub-link-lg-font-size;
$megamenu-secondary-color: var(--tertiary-nav-color, $brand-secondary);
$megamenu-secondary-color-hover: var(--tertiary-nav-color-hover, $brand-primary);
$megamenu-secondary-color-inview: $brand-primary;
$megamenu-secondary-font-weight: var(--tertiary-nav-font-weight, normal);
$megamenu-secondary-text-transform: var(--tertiary-nav-text-transform, none);
$megamenu-secondary-indentation: 2px;
$megamenu-link-padding-top: 10px;
$megamenu-link-padding-bottom: $megamenu-link-padding-top;
$megamenu-link-padding-left: $spacer-sm;
$megamenu-link-padding-right: $megamenu-link-padding-left;
$megamenu-asset-link-list-font-family: $megamenu-header-font;
$megamenu-asset-link-list-font-size: 18px;
$megamenu-asset-link-list-text-transform: none;
$megamenu-asset-link-list-padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom $megamenu-link-padding-left;
$megamenu-asset-link-list-text-decoration: none;
$megamenu-asset-1-flex-basis: 25%;
$megamenu-asset-2-flex-basis: 50%;
$megamenu-subcategory-container-flex-grow-shrink-basis: 1 1 25%; // this plus asset-1 and asset-2 should add up to 100%
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-width: 15%;
$megamenu-left-nav-border-width: 2px;
$megamenu-left-nav-border-color: $off-white;
$megamenu-left-nav-border-color-hover: $brand-secondary;
$megamenu-left-nav-font-size: $megamenu-asset-link-list-font-size;
$megamenu-left-nav-text-transform: var(--secondary-nav-text-transform, $megamenu-header-text-transform);
$megamenu-left-nav-tertiary-title-font-size: $megamenu-header-font-size;
$megamenu-left-nav-tertiary-title-font-weight: $megamenu-header-font-weight;
$megamenu-left-nav-tertiary-title-text-transform: $megamenu-header-text-transform;

//Subcategory styles
$megamenu-subcategory-max-height: 500px;

//bootstrap fix
$dropdown-link-active-color: map-get($content, action-primary-pressed);

//Megamenu Offset View All Link Styles - applies to different level on mega-left vs mega-standard, but refers to the "view all link" that is positioned at the bottom of the dropdown (level 2 or level 3)
$view-all-link-font-weight: bold;
$view-all-link-text-transform: uppercase;

// Search suggestions
$header-suggestions-results-width: 350px;
$header-suggestions-image-size: 30px;
$header-suggestions-header-font: $header-font;
$header-suggestions-header-font-size: 15px;
$header-suggestions-header-color: $black;
$header-suggestions-header-text-transform: none;
$header-suggestions-border: $dropdown-menu-border;
$header-suggestions-item-font: $body-font;
$header-suggestions-item-font-size: $default-text-font-size;
$header-suggestions-item-category-parent-color: $gray;


/*
* FOOTER
*/
$footer-background: var(--footer-background-color, map-get($bg, primary));
$footer-padding-bottom: var(--footer-padding-y, 0);
$footer-padding-top: var(--footer-padding-y, 24px);
$footer-margin-top: var(--footer-margin-top, 0px);
$footer-border-top-color: $border-color;
$footer-border-top-width: 0;
$footer-border-bottom-color: $border-color;
$footer-border-bottom-width: 0;
$footer-title-font-size: 13px;
$footer-title-line-height: 13px;
$footer-title-font-weight: 600;
$footer-link-font-size: 16px;
$footer-title-font-family: $nav-link-font-family;
$footer-title-text-transform: uppercase;
$footer-title-font-color: var(--footer-header-text-color, var(--primary-nav-color, $brand-primary));
$footer-link-color: var(--footer-link-color, var(--secondary-nav-color, $nav-link-color));
$footer-link-color-hover: var(--footer-link-color-hover, var(--secondary-nav-color-hover, var(--primary-nav-color, $brand-secondary)));
$footer-link-height: auto;
$footer-icon-size: 24px;
$footer-copyright-font-size: 11px;
$footer-copyright-color: map-get($content, primary);
$footer-copyright-mix-blend-mode: normal;
$footer-social-links-margin: 20px 12px;
$footer-backtotop-color: map-get($bg, secondary);
$footer-backtotop-icon-color: map-get($content, primary);
$footer-backtotop-size: 32px;
$footer-backtotop-shadow: none;
$footer-hr-margin: 0;
$footer-hr-color: transparent;
$footer-signup-font-size: 16px;
$footer-signup-line-height: 22px;
$footer-signup-letter-spacing: .01em;


/*
* PAGE-LEVEL SETTINGS
*/
$page-header-padding-top: $spacer;
$page-header-padding-bottom: $spacer;
$page-header-padding-top-md: 44px;
$page-header-padding-bottom-md: 44px;
$page-title-header-size: 2;
$page-title-header-font-size-alteration: -4px;


/*
* PRODUCT
*/
// Price
$product-strike-through-price-color: map-get($content, secondary);
$product-strike-through-price-font-size: 12px;
$product-percent-off-price-color: map-get($content, accent);
$product-percent-off-price-font-size: $product-strike-through-price-font-size;

// Ratings
$product-rating-star-icon-font: $icomoon-font-family;
$product-rating-star-color: var(--product-tile-star-color, $black);

// Color swatches - Default type (patterns and colors)
$color-swatch-border-radius: 50%;
$color-swatch-border: 3px solid transparent;
$color-swatch-shadow-size: 3px;
$color-swatch-shadow-inner-size: 2px;
$color-swatch-shadow-color: map-get($border, tertiary);
$color-swatch-shadow: 0 0 0 $color-swatch-shadow-inner-size map-get($border, tertiary), 0 0 0 $color-swatch-shadow-size map-get($border, tertiary);
$color-swatch-hover-shadow-size: $color-swatch-shadow-size;
$color-swatch-hover-shadow-color: map-get($border, primary);
$color-swatch-hover-shadow: 0 0 0 $color-swatch-shadow-inner-size map-get($border, secondary), 0 0 0 $color-swatch-shadow-size map-get($border, primary);
$color-swatch-hover-border: $color-swatch-border;
$color-swatch-selected-shadow-color: map-get($border, tertiary);
$color-swatch-selected-shadow-size: $color-swatch-shadow-size;
$color-swatch-selected-shadow: 0 0 0 $color-swatch-shadow-inner-size map-get($border, secondary), 0 0 0 $color-swatch-shadow-size map-get($border, primary);
$color-swatch-selected-border: $color-swatch-border;
$color-swatch-selected-icon-display: none; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-selected-icon: $icon-checkmark;
$color-swatch-selected-icon-size: 14px;
$color-swatch-selected-icon-color: $white;
$color-swatch-selected-icon-background: rgba($black, 0.7);
$color-swatch-active-border: $color-swatch-border;
$color-swatch-active-shadow: 0 0 0 $color-swatch-shadow-inner-size map-get($border, se), 0 0 0 $color-swatch-shadow-size map-get($border, secondary);
$color-swatch-disabled-shadow: 0 0 0 $color-swatch-shadow-inner-size map-get($border, tertiary), 0 0 0 $color-swatch-shadow-size map-get($border, tertiary);
$color-swatch-disabled-border: $color-swatch-border;

$color-swatch-sm-border: 2px solid transparent;
$color-swatch-sm-shadow-size: 2px;
$color-swatch-sm-shadow-inner-size: 1px;
$color-swatch-sm-shadow: 0 0 0 $color-swatch-sm-shadow-inner-size map-get($border, tertiary), 0 0 0 $color-swatch-sm-shadow-size map-get($border, tertiary);
$color-swatch-sm-hover-shadow: 0 0 0 $color-swatch-sm-shadow-inner-size map-get($border, secondary), 0 0 0 $color-swatch-sm-shadow-size map-get($border, primary);
$color-swatch-sm-selected-shadow: 0 0 0 2px map-get($border, secondary), 0 0 0 3px map-get($border, primary);
$color-swatch-sm-active-shadow: 0 0 0 $color-swatch-sm-shadow-inner-size map-get($border, tertiary), 0 0 0 $color-swatch-sm-shadow-size map-get($border, secondary);
$color-swatch-sm-disabled-shadow: 0 0 0 $color-swatch-sm-shadow-inner-size map-get($border, tertiary), 0 0 0 $color-swatch-sm-shadow-size map-get($border, tertiary);

// Color swatches - Thumbnail type (product image)
$color-swatch-thumbnail-border-radius: 0;
$color-swatch-thumbnail-border: none;
$color-swatch-thumbnail-hover-shadow-color: $dark-gray;
$color-swatch-thumbnail-hover-shadow-size: 2px;
$color-swatch-thumbnail-hover-shadow: inset 0px -#{$color-swatch-thumbnail-hover-shadow-size} 0px $color-swatch-thumbnail-hover-shadow-color;
$color-swatch-thumbnail-hover-border: none;
$color-swatch-thumbnail-selected-shadow-color: $black;
$color-swatch-thumbnail-selected-shadow-size: 2px;
$color-swatch-thumbnail-selected-shadow: inset 0px -#{$color-swatch-thumbnail-selected-shadow-size} 0px $color-swatch-thumbnail-selected-shadow-color;
$color-swatch-thumbnail-selected-border: none;
$color-swatch-thumbnail-selected-icon-display: none; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-thumbnail-selected-icon: $icon-checkmark;
$color-swatch-thumbnail-selected-icon-size: 14px;
$color-swatch-thumbnail-selected-icon-color: $white;
$color-swatch-thumbnail-selected-icon-background: rgba($black, 0.7);
$color-swatch-thumbnail-active-border: none;
$color-swatch-thumbnail-disabled-border: none;

// Non-color swatches (size, width, etc.)
$non-color-swatch-background: $white;
$non-color-swatch-color: $black;
$non-color-swatch-border-radius: 0;
$non-color-swatch-border-width: 1px;
$non-color-swatch-border-color: $gray;
$non-color-swatch-padding: 9px;
$non-color-swatch-hover-border-color: $dark-gray;
$non-color-swatch-hover-box-shadow: none;
$non-color-swatch-selected-border-color: $black;
$non-color-swatch-selected-box-shadow: none;
$non-color-swatch-description-font-size: 12px;
$non-color-swatch-description-font-color: $gray;
$non-color-swatch-selected-background: $white;
$non-color-swatch-selected-color: $black;
$non-color-swatch-selected-icon-display: none;
$non-color-swatch-selected-icon: $icon-checkmark;
$non-color-swatch-selected-icon-size: 16px;
$non-color-swatch-selected-icon-background-color: $brand-primary;
$non-color-swatch-selected-icon-color: $white;

// PDP
$pdp-product-detail-top-spacer: $spacer * 2;
$pdp-primary-images-position-type: sticky;
$pdp-primary-images-top-value: var(--header-height);
$pdp-primary-images-z-index: 1;
$pdp-breadcrumb-wishlist-line-margin-bottom: $spacer * 1.5;
$pdp-badge-above-title-margin-bottom: calc(11px - #{$spacer-sm});
$pdp-badge-above-title-padding: 0;
$pdp-badge-above-title-flex-direction: row;
$pdp-badge-above-title-banner-value: 4;
$pdp-badge-above-title-background-color: transparent;
$pdp-badge-above-title-border-radius: 50px;
$pdp-name-header-value: 2;
$pdp-name-header-color: map-get($content, primary);
$pdp-price-font-size: 24px;
$pdp-price-color: map-get($content, primary);
$pdp-price-font: $body-font;
$pdp-price-margin-right: $spacer-sm;
$pdp-product-number-font-size: 12px;
$pdp-product-number-color: $dark-gray;
$pdp-product-slider-image-border-radius: $slider-thumbnail-active-border-border-radius;
$pdp-rating-star-size: 10px;
$pdp-rating-star-size-large: 20px;
$pdp-rating-star-size-large-md: $pdp-rating-star-size-large;
$pdp-rating-star-margin-left: 0px;
$pdp-rating-star-margin-bottom: 0;
$pdp-reviews-link-display: none;
$pdp-reviews-link-font-size: 12px;
$pdp-reviews-link-color: map-get($content, secondary);
$pdp-reviews-link-color-hover: $dark-gray;
$pdp-right-column-spacing-md: $spacer; // desktop only (additional space between images and data
$pdp-right-column-spacing-lg: 24px; // ""
$pdp-right-column-spacing-xl: $pdp-right-column-spacing-lg; // ""
$pdp-attribute-label-font-size: $default-text-font-size;
$pdp-attribute-non-input-label-color: map-get($content, secondary);
$pdp-attribute-vertical-spacing: 24px;
$pdp-attribute-vertical-spacing-md: 24px;
$pdp-attribute-vertical-spacing-lg: 44px;
$pdp-color-swatch-spacing: 12px;
$pdp-color-swatch-size: var(--pdp-color-swatch-size, 44px);
$pdp-color-swatch-thumbnail-size: var(--pdp-color-swatch-thumbnail-size, 48px);
$pdp-color-swatch-thumbnail-spacing: 1px;
$pdp-non-color-swatch-min-width: var(--pdp-non-color-swatch-size, $pdp-color-swatch-thumbnail-size);
$pdp-non-color-swatch-spacing: 8px;
$pdp-add-to-cart-button-icon: $icon-content-blank;
$pdp-promo-color: $base-font-color;
$pdp-social-icon-color: $link-color;
$pdp-social-icon-color-hover: $link-color-hover;
$pdp-social-icon-size: 30px;
$pdp-social-icon-spacing: 30px;
$pdp-airline-carry-on-icon-size: $slider-button-icon-size;
$pdp-description-detail-header-value: 4;
$pdp-description-detail-divider: 1px solid $border-color; // only applies when these are collapsible
$pdp-description-detail-vertical-spacing: $spacer;
$pdp-description-detail-vertical-spacing-md: 30px;
$pdp-description-detail-vertical-spacing-lg: 60px;
$pdp-tabs-vertical-spacing: $spacer;
$pdp-tabs-vertical-spacing-md: 30px;
$pdp-tabs-vertical-spacing-lg: 60px;
$pdp-asset-max-width: 700px;
$pdp-tabs-header-value: 4;
$pdp-product-has-thumbnails-content-padding-left: $slider-thumbnail-width-lg;
$pdp-description-drawer-column-padding: $spacer * 2;
$pdp-description-drawer-content-column-width: 8; // numerical value 0-12
$pdp-description-drawer-image-column-width: 4; // numerical value 0-12
$pdp-description-drawer-image-border-radius: $pdp-product-slider-image-border-radius;
$pdp-specification-drawer-specs-column-width: $pdp-description-drawer-content-column-width; // numerical value 0-12
$pdp-specification-drawer-size-guide-buttons-column-width: $pdp-description-drawer-image-column-width; // numerical value 0-12
$pdp-specification-drawer-specs-item-spacing-top-bottom: calc(#{$spacer} * 2);
$pdp-specification-drawer-specs-item-spacing-left-right: calc(#{$pdp-specification-drawer-specs-item-spacing-top-bottom} / 2);
$pdp-specification-drawer-specs-item-min-width: 175px;
$pdp-specification-drawer-specs-item-border-top-width: 1px;
$pdp-specification-drawer-specs-item-border-top-color: map-get($border, tertiary);
$pdp-specification-drawer-specs-name-margin-bottom: calc(#{$spacer} / 2);
$pdp-specification-drawer-specs-name-banner-value: 5; // should be banner value 1-4
$pdp-specification-drawer-specs-name-color: map-get($content, secondary);
$pdp-specification-drawer-specs-value-font-weight: 600;

//PDP Suggested Products Popover
$pdp-suggested-products-detail-panel-padding-top-bottom: 0px;

// PDP STICKY ADD TO CART
$pdp-sticky-addtocart-background: $white;
$pdp-sticky-addtocart-opacity: 1;
$pdp-sticky-addtocart-open-transition: transform $transition-speed linear;
$pdp-sticky-addtocart-shadow: $dropdown-menu-shadow;
$pdp-sticky-addtocart-name-header-value: 4;
$pdp-sticky-addtocart-color-swatch-size: $pdp-color-swatch-size;
$pdp-sticky-addtocart-color-swatch-spacing: 10px;
$pdp-sticky-addtocart-color-swatch-thumbnail-size: $input-sm-height;
$pdp-sticky-addtocart-color-swatch-thumbnail-spacing: $pdp-color-swatch-thumbnail-spacing;
$pdp-sticky-addtocart-attribute-spacing: $spacer;
$pdp-sticky-addtocart-attribute-select-font-size: $input-sm-font-size;
$pdp-sticky-addtocart-attribute-select-line-height: $input-sm-font-line-height;
$pdp-sticky-addtocart-attribute-select-padding: $input-sm-padding-top $select-sm-padding-right $input-sm-padding-bottom $input-sm-padding-left;
$pdp-sticky-addtocart-attribute-select-height: $input-sm-height;
$pdp-sticky-addtocart-button-font-size: $button-sm-font-size;
$pdp-sticky-addtocart-button-line-height: $button-sm-line-height;
$pdp-sticky-addtocart-button-padding: calc(min(10px, 2vh)) 0px;
$pdp-sticky-addtocart-button-letter-spacing: $button-sm-letter-spacing;

// QUICKVIEW
$quickview-selected-bonus-product-font-size: 10px;
$quickview-selected-bonus-product-font-size-sm: 16px;
$grid-quickview-padding: $spacer-xl;
$grid-image-spacing: $spacer;

//PHOTOSWIPE --OOTB
//PHOTOSWIPE
$photoswipe-bg: map-get($bg, overlay);
$photoswipe-placeholder-bg: $black;
$photoswipe-root-z-index: 100000;
$photoswipe-preloader-color: rgba($brand-primary, 0.5);
$photoswipe-preloader-color-secondary: $brand-primary;
$photoswipe-icon-arrow-color: map-get($content, primary);
$photoswipe-icon-arrow-size: 32px;
$photoswipe-icon-arrow-background: $white;
$photoswipe-icon-arrow-border-radius: 50%;
$photoswipe-icon-color: $white;
$photoswipe-icon-size: 20px;
$photoswipe-icon-color-secondary: $brand-primary;
$photoswipe-icon-stroke-color:  $brand-secondary;
$photoswipe-icon-stroke-width: 2px;
$photoswipe-image-background: $white;
$photoswipe-error-text-color: var(--pswp-icon-color);

// PRODUCT CARDS (order detail, checkout summary, order confirmation)
$product-card-image-size-sm: 100px;
$product-card-image-size-lg: 176px; // used on cart page large screens
$product-card-product-name-font-family: $primary-font;
$product-card-product-name-font-size: normal; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$product-card-product-name-text-transform: none;
$product-card-product-name-font-weight: normal;
$product-card-product-name-text-decoration: none;
$product-card-attribute-size: $default-text-font-size;
$product-card-attribute-color: $dark-gray;
$product-card-border-color: $card-body-border-color;
$product-card-item-border-width: 1px;
$product-card-price-font-size: $default-text-font-size; // Custom
$product-card-price-margin-top: calc(#{$spacer} / 2); // Custom
$product-card-price-qty-row-background: $white;
$product-card-price-qty-row-border-color: $card-body-border-color;
$product-card-remove-icon: $icon-close;
$product-card-remove-icon-size: 11px;
$product-card-remove-icon-background: $white;
$product-card-promo-color: $green;
$product-card-promo-font-size: $default-text-font-size;

// PLP - PRODUCT GRID
$plp-name-header-display-with-slot: none;
$plp-product-compare-image-max-height: 120px;
$product-compare-item-min-width: 150px;
$product-refinement-header-value: 5;
$product-refinement-font-size: $radiocheck-label-font-size;
$product-refinement-swatch-size: 20px;
$product-refinement-swatch-img-size: 30px;
$product-refinement-swatch-border: 1px solid rgba($black, 0.3);
$product-refinement-swatch-spacing: 12px;
$product-refinement-non-color-swatch-min-width: 44px;
$product-refinement-non-color-swatch-spacing: 10px;
$product-refinements-drawer-width: calc(100% - 50px);
$product-refinements-drawer-max-width: $header-suggestions-results-width; // mobile only
$product-refinements-drawer-padding-bottom: 200px;
$product-refinement-secondary-link-text-decoration: underline;
$product-refinement-secondary-link-text-color: map-get($content, secondary);
$product-refinement-secondary-link-padding: 12px 0;
$product-refinement-secondary-link-line-height: $default-text-line-height;
$product-refinement-secondary-bar-border-width: 0px;
$product-refinement-secondary-bar-border-style: solid;
$product-refinement-secondary-bar-border-color: transparent;
$product-refinement-header-padding: 20px;
$product-refinement-header-background-color: $modal-header-background-color;
$product-refinement-header-border-color: $modal-header-border-color;
$product-refinement-header-color: map-get($content, primary);
$product-refinement-header-title-size: 4;
$product-refinement-value-padding-bottom: 12px;
$product-refinement-value-grid-padding-bottom: 10px;
$product-refinement-result-count-color: map-get($content, secondary);
$product-filter-font-size: 13px;
$product-filter-font-weight: normal;
$product-filter-color: $black;
$product-filter-border: 1px solid map-get($border, secondary);
$product-filter-border-radius: $border-radius;
$product-filter-padding-top: 8px;
$product-filter-padding-right: 36px;
$product-filter-padding-bottom: 7px;
$product-filter-padding-left: 12px;
$product-filter-background: $white;
$product-filter-icon-size: 12px;
$product-filter-spacing: $spacer-sm;
$product-filter-sort-order-font-weight: normal;
$product-filter-swatch-size: $product-filter-font-size + $product-filter-padding-top + $product-filter-padding-bottom + 2px;
$product-miscellaneous-swatch-background: linear-gradient(0deg, rgba(130, 30, 145, 1) 0, rgba(130, 30, 145, 1) 25%, rgba(237, 209, 52, 1) 25%, rgba(255, 255, 0, 1) 50%, rgba(237, 209, 52, 1) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgba(14, 92, 209, 1) 0, rgba(14, 92, 209, 1) 50%, rgba(226, 11, 11, 1) 50%, rgba(226, 11, 11, 1) 100%);

$product-refinement-horizontal-padding-top: $spacer;
$product-refinement-horizontal-padding-bottom: $spacer;
$product-refinement-horizontal-dropdown-menu-padding: 16px;
$product-refinement-horizontal-dropdown-menu-max-width: 300px;

$product-refinement-horizontal-onedropdown-border-top: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-border-bottom: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-border-right: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-border-left: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-margin-top: 15px;
$product-refinement-horizontal-onedropdown-max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, xl);
$product-refinement-horizontal-onedropdown-refinement-padding-top: 15px;
$product-refinement-horizontal-onedropdown-refinement-padding-bottom: 15px;
$product-refinement-horizontal-onedropdown-btn-padding-top: 6px;
$product-refinement-horizontal-onedropdown-btn-padding-bottom: 6px;
$product-refinement-horizontal-onedropdown-btn-padding-right: 10px;
$product-refinement-horizontal-onedropdown-btn-padding-left: 12px;
$product-refinement-horizontal-onedropdown-btn-border-top: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-bottom: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-right: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-left: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-background-color: transparent;
$product-refinement-horizontal-onedropdown-btn-width: 150px;
$product-refinement-horizontal-onedropdown-sortby-btn-width: 150px;
$product-refinement-horizontal-onedropdown-filterby-btn-width: 12px;
$product-refinement-horizontal-onedropdown-filterby-btn-height: 12px;
$product-refinement-horizontal-onedropdown-sticky-bar-left: 0;
$product-refinement-horizontal-onedropdown-sticky-bar-zindex: $z-index-header - 2;
$product-refinement-horizontal-onedropdown-sticky-bar-padding: $spacer;
$product-refinement-horizontal-onedropdown-sticky-bar-background-color: $white;
$product-refinement-horizontal-onedropdown-sticky-bar-border-bottom: 0px solid $border-color;
$product-refinement-horizontal-onedropdown-sticky-bar-bottom-shadow: $header-fixed-shadow;

$product-refinement-horizontal-multipledropdowns-btn-border-top: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-bottom: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-left: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-right: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-padding-top: 6px;
$product-refinement-horizontal-multipledropdowns-btn-padding-bottom: 6px;
$product-refinement-horizontal-multipledropdowns-btn-padding-left: 12px;
$product-refinement-horizontal-multipledropdowns-btn-padding-right: 10px;
$product-refinement-horizontal-multipledropdowns-height: calc(16px + 12px + 14px + (1px*2));
$product-refinement-horizontal-multipledropdowns-margin-right: 10px;
$product-refinement-horizontal-multipledropdowns-margin-bottom: 0;
$product-refinement-horizontal-multipledropdowns-min-width: 0px;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-min-width: 300px;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-margin-top: 0;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom: 20px;

// PRODUCT TILES (product grid, recommended sliders)
$product-tile-grid-margin-bottom: 45px;
$product-tile-body-text-alignment: var(--product-tile-text-alignment, left);
$product-tile-image-hover-shadow-color: $black;
$product-tile-image-hover-shadow-size: 2px;
$product-tile-image-hover-shadow: inset 0px -#{$product-tile-image-hover-shadow-size} 0px $product-tile-image-hover-shadow-color;
$product-tile-image-margin-bottom: 10px;
$product-tile-image-transformation: scale(1.035);
$product-tile-image-container-max-height: 400px;
$product-tile-swatch-size: var(--product-tile-swatch-size, 14px);
$product-tile-swatch-spacing: 7px;
$product-tile-swatch-thumbnail-size: var(--product-tile-thumbnail-size, 30px);
$product-tile-swatch-thumbnail-spacing: 1px;
$product-tile-swatch-margin-bottom: 10px;
$product-tile-swatch-color-count-color: map-get($content, secondary);
$product-tile-swatch-color-count-font-size: 12px;
$product-tile-swatch-color-count-letter-spacing: -0.005em;
$product-tile-swatch-color-count-text-decoration: none;
$product-tile-title-font: var(--product-name-font, $primary-font);
$product-tile-title-font-size-mobile: var(--product-name-font-size-mobile, $default-text-font-size);
$product-tile-title-font-size-desktop: var(--product-name-font-size-desktop, $default-text-font-size);
$product-tile-title-font-weight: 400;
$product-tile-title-text-decoration: none;
$product-tile-title-text-decoration-hover: none;
$product-tile-title-text-transform: none;
$product-tile-title-color: $base-font-color;
$product-tile-title-color-hover: $base-font-color;
$product-tile-title-margin-bottom: 2px;
$product-tile-title-header-value: 6;
$product-tile-subtitle-font-weight: normal;
$product-tile-subtitle-font-size: $base-font-size;
$product-tile-subtitle-margin-top: $product-tile-title-margin-bottom;
$product-tile-subtitle-color: $product-tile-title-color;
$product-tile-price-font-size: var(--product-tile-price-size, 16px);
$product-tile-price-color: var(--product-tile-price-color, $base-font-color);
$product-tile-price-strike-through-color: map-get($content, secondary);
$product-tile-price-strike-through-font-size: 12px;
$product-tile-price-margin-bottom: 2px;
$product-tile-sale-color: var(--product-tile-sale-color, $sale);
$product-tile-promotion-color: map-get($content, secondary);
$product-tile-promotion-font-size: 12px;
$product-tile-promotion-margin-bottom: 0;
$product-tile-rating-margin-bottom: 4px;
$product-tile-rating-star-color: $product-rating-star-color;
$product-tile-rating-star-size: var(--product-tile-star-size, 11px);
$product-tile-rating-star-padding: 6px;
$product-tile-reviews-link-display: none;
$product-tile-reviews-link-font-size: 11px;
$product-tile-reviews-link-color: $light-gray;
$product-tile-reviews-link-color-hover: $dark-gray;
$product-tile-quickview-link-icon: $icon-plus;
$product-tile-quickview-link-size: 16px; // Quickview link appears on desktop only
$product-tile-quickview-link-icon-size: 10px;
$product-tile-quick-add-to-cart-link-icon-size: 20px;
$product-tile-quickview-link-color: map-get($content, primary);
$product-tile-quickview-link-font-size: 12px;
$product-tile-quickview-link-text-decoration: underline;
$product-tile-quickview-link-background-color: rgba($white, 0.7);
$product-tile-quickview-link-box-shadow: none;
$product-tile-quick-action-buttons-spacer: $spacer;
$product-tile-wishlist-link-icon: $icon-favorite;
$product-tile-wishlist-link-icon-selected: $icon-favorite-selected;
$product-tile-wishlist-link-size: 30px;
$product-tile-wishlist-link-icon-size: 16px;
$product-tile-wishlist-link-icon-sm-size: 10px;
$product-tile-wishlist-link-color: map-get($content, primary);
$product-tile-wishlist-link-background-color: transparent;
$product-tile-wishlist-link-box-shadow: none;
$product-tile-wishlist-link-border-radius: 50%;

//PRODUCT TILE PRE POST IMAGE SECTIONS
$product-tile-pre-post-image-margin-bottom: 4px;
$product-tile-pre-post-image-default-height: 24px;

// PRODUCT TILE (small)
$product-tile-sm-title-font: var(--product-name-font, $header-font);
$product-tile-sm-title-font-size: 14px;
$product-tile-sm-title-font-size-desktop: var(--product-name-font-size-desktop, $product-tile-title-font-size-mobile);
$product-tile-sm-title-font-weight: normal;
$product-tile-sm-title-text-decoration: none;
$product-tile-sm-title-text-decoration-hover: none;
$product-tile-sm-title-text-transform: none;
$product-tile-sm-title-color: $base-font-color;
$product-tile-sm-title-color-hover: $base-font-color;
$product-tile-sm-title-margin-bottom: 2px;
$product-tile-sm-title-text-transform: none;

// PRODUCT TILES (badges)
$product-tile-badges-margin: 1px;
$product-tile-badge-padding: 2px 3px;
$product-tile-badge-padding-lg: 5px 6px;
$product-tile-badge-margin-bottom: 1px;
$product-tile-badge-font-weight: normal;
$product-tile-badge-font-size: 10px;
$product-tile-badge-font-size-lg: $default-text-font-size;
$product-tile-badge-text-align: left;
$product-tile-badge-line-height: $default-text-line-height;
$product-tile-badge-font-family: $body-font;
$product-tile-badge-background: $white;
$product-tile-badge-color: $base-font-color;
$product-tile-badge-border-radius: $border-radius;
$product-tile-badge-border-width: 1px;
$product-tile-badge-border-style: solid;
$product-tile-badge-border-color: transparent;
// dark style
$product-tile-badge-dark-background: $base-font-color;
$product-tile-badge-dark-color: $white;
$product-tile-badge-dark-border-color: transparent;

// PRODUCT SETS
$product-set-list-padding-top: $spacer;
$product-set-contains-header-value: 5; // This number should be 1-6, corresponding with h1-h6 headers
$product-set-contains-padding: $spacer-xl 0;
$product-set-contains-margin: $product-set-contains-padding;
$product-set-contains-border-width: 1px;
$product-set-contains-background: transparent;
$product-set-item-list-padding-top: $product-set-list-padding-top;
$product-set-item-list-margin-top: $product-set-item-list-padding-top - $spacer;
$product-set-item-list-product-name-header-value: 4; // This number should be 1-6, corresponding with h1-h6 headers
$product-set-item-list-product-name-header-color: $pdp-name-header-color;
$product-set-item-list-product-price-header-value: $product-set-item-list-product-name-header-value; // This number should be 1-6, corresponding with h1-h6 headers
$product-set-item-list-product-price-color: $product-set-item-list-product-name-header-color;
$product-set-item-list-product-name-text-decoration: none;
$product-set-item-list-headline-and-description-display: block;
$product-set-item-list-attr-margin-top: calc(#{$spacer} / 2);
$product-set-item-list-border-color: $border-color;
$product-set-item-list-border-width: 1px;
$product-set-with-thumbnails-content-margin-left: calc(#{$slider-thumbnail-width-lg} - #{$slider-thumbnail-spacing});

// PRODUCT BUNDLES
$product-bundle-item-name-font-size: 3; // This number should be 1-6, corresponding with h1-h6 headers
$product-bundle-item-min-width: 400px;
$product-bundle-item-grid-gap: $spacer*2 $spacer;
$product-bundle-item-top-border-color: $border-color;
$product-bundle-item-pdp-attribute-vertical-spacing: calc(#{$spacer} / 2);
$product-bundle-item-pdp-attribute-vertical-spacing-md: $product-bundle-item-pdp-attribute-vertical-spacing;
$product-bundle-item-pdp-attribute-vertical-spacing-lg: $product-bundle-item-pdp-attribute-vertical-spacing-md;


/*
* MINICART
*/
$minicart-size: 350px;
$minicart-min-height: 200px;
$minicart-totals-font-family: $primary-font;
$minicart-totals-font-size: small; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$minicart-totals-font-weight: bold;
$minicart-totals-text-transform: none;


/*
* CART AND CHECKOUT
*/
$cart-card-margin-bottom: -1px;
$cart-number-of-items-font-size: $default-text-font-size;
$cart-product-name-text-overflow: ellipsis;
$cart-product-name-white-space: wrap;
$cart-product-quantity-label-display: none;
$cart-recommended-products-border-top: 1px solid $border-color;
$cart-recommended-products-margin-top:  48px; // container margin
$cart-recommended-products-padding-top: 48px; // container padding
$checkout-mobile-footer-background: rgba($white, 1);
$checkout-mobile-footer-box-shadow: 0 -4px 4px rgba($black, 0.1);
$checkout-approaching-discount-color: $green;
$checkout-promo-message-color: $green;
$checkout-totals-font-family: $primary-font;
$checkout-totals-font-size: 16px;
$checkout-totals-font-weight: normal;
$checkout-totals-text-transform: none;
$checkout-spacer: 41px;
$checkout-btn-spacer: 10px;
$checkout-selected-payment-background: $off-white;
$checkout-selected-payment-color: $brand-primary;
$checkout-selected-payment-border: 1px solid $brand-primary;
$checkout-step-header-value: 5;
$checkout-step-header-or-banner: header;
$checkout-step-number-width: $spacer;
$checkout-step-number-font-size: 50%;

/*
* PAGE DESIGNER MODULES
*/
$page-designer-layout-spacing: $spacer*2; // This sets the default spacing between layout containers


/*
* HERO ASSETS
*/
$hero-title-size: 1; // This number should be 1-6, corresponding with h1-h6 headers
$hero-subtitle-font: $header-font;
$hero-subtitle-font-size: $paragraph-font-size-small;
$hero-subtitle-text-transform: uppercase;
$hero-textblock-outer-padding: $spacer;
$hero-textblock-outer-padding-md: $spacer;
$hero-textblock-outer-padding-lg: 50px;
$hero-textblock-inner-padding: $spacer;
$hero-textblock-inner-padding-md: $spacer;
$hero-textblock-inner-padding-lg: $spacer;
$hero-textblock-background-opacity: 0.7;
$hero-textblock-border-width: 1px;
$hero-textblock-items-vertical-spacing: $spacer;
$hero-min-height-short: 64px;
$hero-min-height-short-md: 112px;
$hero-min-height-short-lg: 112px;
$hero-min-height-medium: 430px;
$hero-min-height-medium-md: 450px;
$hero-min-height-medium-lg: 450px;
$hero-min-height-tall: 520px;
$hero-min-height-tall-md: 620px;
$hero-min-height-tall-lg: 620px;
$hero-colortheme-dark: $black;
$hero-colortheme-light: $white;
$hero-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$hero-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;
$hero-animation-delay: 0ms; // This value should match the ComponentAnimationDelay value in scripts/constants/SiteConstants.js
$hero-hr-overhang: calc(#{$spacer} * 3);
$hero-hr-thickness: 1px;
$hero-embedded-image-width: 160px;
$hero-embedded-image-border-radius: 50%;

/*
* CATEGORY TILE ASSETS
*/
$category-tile-border-radius: $border-radius;
$category-tile-primary-font-size: $paragraph-font-size-large;
$category-tile-secondary-font-size: $paragraph-lg-font-size;
$category-tile-text-shadow: 0 0 2px rgba($black, 0.6); // When text is in image area only
$category-tile-text-background: rgba($black, 0.3); // When text is in image area only
$category-tile-text-inside-color: $white; // When text is in image area only
$category-tile-hover-zoom-amount: 1.2; // Only applicable if zoom is enabled
$category-tile-aspect-ratio-square: $aspect-ratio-square;
$category-tile-aspect-ratio-landscape: $aspect-ratio-landscape;
$category-tile-aspect-ratio-portrait: $aspect-ratio-portrait;
$category-tile-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$category-tile-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;


/*
* IMAGE TEXT BLOCK ASSETS
*/
$image-text-block-border-radius: $category-tile-border-radius;
$image-text-block-subtitle-font-size: $paragraph-font-size-small;
$image-text-block-primary-font-size: $category-tile-primary-font-size;
$image-text-block-secondary-font-size: $category-tile-secondary-font-size;
$image-text-block-text-shadow: $category-tile-text-shadow; // When text is in image area only
$image-text-block-text-background: $category-tile-text-background; // When text is in image area only
$image-text-block-text-inside-color: $category-tile-text-inside-color; // When text is in image area only
$image-text-block-hover-zoom-amount: $category-tile-hover-zoom-amount; // Only applicable if zoom is enabled
$image-text-block-aspect-ratio-square: $category-tile-aspect-ratio-square;
$image-text-block-aspect-ratio-landscape: $category-tile-aspect-ratio-landscape;
$image-text-block-aspect-ratio-portrait: $category-tile-aspect-ratio-portrait;
$image-text-block-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$image-text-block-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;


/*
* PHOTO HOTSPOTS ASSETS
*/
$photo-hotspot-size: 12px;
$photo-hotspot-background: map-get($bg, inverse);
$photo-hotspot-background-dark: map-get($bg, primary);
$photo-hotspot-border-color-inner: transparent;
$photo-hotspot-border-color-inner-dark: transparent;
$photo-hotspot-border-size-inner: 0;
$photo-hotspot-border-color-outer: rgba($white, 0.6);
$photo-hotspot-border-color-outer-dark: rgba($black, 0.5);
$photo-hotspot-border-size-outer: 8px;
$photo-hotspot-border-radius: 50%;
$photo-hotspot-icon: none;
$photo-hotspot-icon-color: map-get($bg, inverse);
$photo-hotspot-icon-color-dark: map-get($bg, primary);
$photo-hotspot-icon-size: 12px;
$photo-hotspot-shadow: none;
$photo-hotspot-size-hover: 40px;
$photo-hotspot-background-hover: rgba($white, 0.8);
$photo-hotspot-background-hover-dark: rgba($black, 0.5);
$photo-hotspot-border-color-inner-hover: transparent;
$photo-hotspot-border-color-inner-hover-dark: transparent;
$photo-hotspot-border-size-inner-hover: 0;
$photo-hotspot-border-color-outer-hover: rgba($white, 0.6);
$photo-hotspot-border-color-outer-hover-dark: rgba($black, 0.5);
$photo-hotspot-border-size-outer-hover: 3px;
$photo-hotspot-hover-icon: $icon-plus;
$photo-hotspot-tooltip-max-width: 300px;
$photo-hotspot-text-spacing: 8px;
$photo-hotspot-text-product-divider: 1px solid $border-color;
$photo-hotspot-attribute-spacing: 8px;
$photo-hotspot-tooltip-image-size: 75px;
$photo-hotspot-tooltip-product-name-font-size: $default-text-font-size;
$photo-hotspot-tooltip-product-name-color: $black;
$photo-hotspot-tooltip-product-name-text-overflow: ellipsis;
$photo-hotspot-tooltip-product-name-whitespace: nowrap;
$photo-hotspot-tooltip-price-font-size: $default-text-font-size;
$photo-hotspot-tooltip-price-color: $dark-gray;
$photo-hotspot-tooltip-message-display: block;
$photo-hotspot-tooltip-message-font-size: 10px;
$photo-hotspot-tooltip-message-text-transform: uppercase;
$photo-hotspot-tooltip-message-color: $dark-gray;
$photo-hotspot-tooltip-sale-price-color: $sale;


/*
* VIDEO ASSETS
*/
$video-play-icon: $icon-play-hollow;
$video-play-icon-color: $white;
$video-play-icon-size: 50px;
$video-pause-icon: $icon-pause;
$video-pause-icon-color: $white;
$video-pause-icon-color-hover: $brand-secondary;
$video-pause-icon-size: 50px;
$video-modal-size: 'xl'; // sm, lg, or xl


/*
* BLOG LANDING
*/
$blog-nav-background-color: $off-white;
$blog-nav-border-color: $border-color;
$blog-nav-item-text-decoration: none;
$blog-nav-item-active-font-weight: bold;


/*
* BLOG DETAIL
*/
$blog-detail-category-color: $dark-gray;
$blog-detail-date-color: $dark-gray;
$blog-detail-category-date-separator-color: $dark-gray;
$blog-detail-category-date-separator: '|';
$blog-detail-category-date-separator-spacer-left: 10px;
$blog-detail-category-date-separator-spacer-right: 10px;
$blog-detail-title-size: 2; // This number should be 1-6, corresponding with h1-h6 headers
$blog-detail-title-margin-top: 10px;
$blog-detail-author-color: $dark-gray;
$blog-detail-author-margin-top: 10px;
$blog-detail-social-icons-border-top: none;
$blog-detail-social-icons-margin-top: 80px;
$blog-detail-social-icons-padding-top: 0;
$blog-detail-social-icon-color: $link-color;
$blog-detail-social-icon-color-hover: $link-color-hover;
$blog-detail-social-icon-size: 30px;
$blog-detail-social-icon-spacing: 30px;
$blog-detail-footer-text-align: center;
$blog-detail-search-words-border-top: 1px solid $border-color;
$blog-detail-search-words-margin-top: 20px;
$blog-detail-search-words-padding-top: 20px;
$blog-detail-search-words-separator: ',';
$blog-detail-search-words-spacer-left: 0;
$blog-detail-search-words-spacer-right: 7px;


/*
* CONTENT TILES
*/
$content-tile-image-aspect-ratio: $aspect-ratio-square;
$content-tile-image-zoom-amount: 1.2; // set to 1 to turn zooming off
$content-tile-grid-margin-bottom: $product-tile-grid-margin-bottom;
$content-tile-image-hover-shadow-color: $product-tile-image-hover-shadow-color;
$content-tile-image-hover-shadow-size: $product-tile-image-hover-shadow-size;
$content-tile-image-hover-shadow: $product-tile-image-hover-shadow;
$content-tile-image-margin-bottom: $product-tile-image-margin-bottom;
$content-tile-category-font: $default-text-font;
$content-tile-category-font-size: $paragraph-font-size-small;
$content-tile-category-font-weight: $product-tile-title-font-weight;
$content-tile-category-text-decoration: $product-tile-title-text-decoration;
$content-tile-category-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-category-color: $dark-gray;
$content-tile-category-color-hover: $dark-gray;
$content-tile-category-margin-bottom: 7px;
$content-tile-name-font: $header-font;
$content-tile-name-font-size: 18px;
$content-tile-name-font-weight: $product-tile-title-font-weight;
$content-tile-name-text-decoration: $product-tile-title-text-decoration;
$content-tile-name-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-name-color: $product-tile-title-color;
$content-tile-name-color-hover: $product-tile-title-color-hover;
$content-tile-name-margin-bottom: 10px;
$content-tile-date-font: $header-font;
$content-tile-date-font-size: $paragraph-font-size-small;
$content-tile-date-font-weight: $product-tile-title-font-weight;
$content-tile-date-text-decoration: $product-tile-title-text-decoration;
$content-tile-date-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-date-text-transform: uppercase;
$content-tile-date-color: $product-tile-title-color;
$content-tile-date-color-hover: $product-tile-title-color-hover;
$content-tile-date-margin-bottom: 7px;
$content-tile-description-font: $default-text-font;
$content-tile-description-font-size: $default-text-font-size;
$content-tile-description-font-weight: $default-text-font-weight;
$content-tile-description-text-decoration: none;
$content-tile-description-text-decoration-hover: none;
$content-tile-description-color: $base-font-color;
$content-tile-description-color-hover: $base-font-color;


/*
* DISCOUNT STATUS BAR
*/
$discount-status-bar-background: $dark-gray;
$discount-status-bar-text-color: $white;
$discount-status-bar-padding: $spacer;
$discount-status-bar-promo-message-font-size: $default-text-font-size;
$discount-status-bar-promo-message-line-height: $default-text-line-height;
$discount-status-bar-promo-message-margin-bottom: 8px;
$discount-status-bar-threshold-font-size: 13px;
$discount-status-bar-threshold-line-height: $default-text-line-height;
$discount-status-bar-threshold-margin-top: 8px;
$discount-status-bar-icon: $icon-checkmark;
$discount-status-bar-icon-size: 24px;
$discount-status-bar-icon-border-radius: 50%;
$discount-status-bar-icon-color: $white;
$discount-status-bar-icon-background-color: $light-gray;
$discount-status-bar-icon-active-color: $white;
$discount-status-bar-icon-active-background-color: $green;
$discount-status-bar-progress-height: 8px;
$discount-status-bar-progress-border-color: $white;
$discount-status-bar-progress-border-radius: 4px;
$discount-status-bar-progress-border-size: 1px;
$discount-status-bar-progress-fill-background-color: $white;
$discount-status-bar-total-font: $primary-font;
$discount-status-bar-total-font-size: 20px;
$discount-status-bar-total-line-height: $default-text-line-height;
$discount-status-bar-total-margin-bottom: $spacer;
$discount-status-bar-checkout-btn-style: secondary;
$discount-status-bar-completion-text-color: $white;
$discount-status-bar-completion-text-font: $primary-font;
$discount-status-bar-completion-text-font-size: $default-text-font-size;
$discount-status-bar-completion-text-line-height: $default-text-line-height;
$discount-status-bar-completion-text-margin-bottom: $discount-status-bar-total-margin-bottom;


/*
* STORE LOCATOR
*/
$store-map-canvas-height-sm: 250px;
$store-map-canvas-height: 450px;
$store-map-canvas-marker-font-family: $primary-font;
$store-map-canvas-marker-title-font-size: 18px;
$store-map-canvas-marker-font-size: $popover-font-size;
$store-map-canvas-marker-line-height: 1.5;
$store-map-marker-size: 32px;
$store-results-container-max-height: 350px;
$store-type-map-marker-default-label-color: $white;


/*
* STORE DETAIL PAGE
*/
$store-detail-name-header-value: 3;
$store-detail-name-header-color: $black;
$store-detail-attribute-vertical-spacing: 8px;
$store-detail-attribute-label-font-weight: bold;
$store-detail-additional-margin-top: 30px;
$store-detail-image-margin-top: 30px;
$store-detail-map-link-margin-top: $spacer;


/*
* ACCOUNT
*/
$account-login-tabs-margin-bottom: 20px;
$account-wishlist-social-link-size: 30px;
$account-wishlist-social-link-icon-size: 30px;
$account-wishlist-social-link-color: rgba($black, 0.7);
$account-wishlist-social-link-background-color: rgba($white, 0.7);
$account-wishlist-social-link-box-shadow: none;
$account-wishlist-social-link-border-radius: 50%;


/*
* WISHLIST
*/
$wishlist-area-card-header-background: transparent;
$wishlist-area-card-border-color: $light-gray;
$wishlist-area-card-border-size: 1px;
$wishlist-area-card-border-radius: $border-radius;
$wishlist-area-icon-size: 14px;
$wishlist-area-icon-color: $dark-gray;
$wishlist-area-header-link-color-hover: $dark-gray;
$wishlist-area-header-link-text-decoration-hover: underline;
$wishlist-area-max-products-shown-per-list-card: 4;
$wishlist-details-text-area-height: 100px;
$wishlist-public-icon: $icon-globe;
$wishlist-public-icon-color: $white;
$wishlist-public-background-color: $brand-secondary;
$wishlist-modal-header-icon-back: $icon-arrow-left-thin;
$wishlist-modal-header-icon-close: $icon-close;
$wishlist-modal-header-icon-color: $white;
$wishlist-modal-list-image-size: 40px;
$wishlist-toast-distance-from-top: 40px;

/*
* GIFTCERTIFICATES
*/
$giftcertificate-balancemsg-font-size: 18px;
$giftcertificate-balancemsg-font-weight: bold;


/*
* PAYMENTMETHODS
*/
$paymentmethod-error-font-weight: bold;


/*
* ACCESSIBILITY
*/
$outline-focus-width: 2px;
$outline-focus-style: solid;
$outline-focus-color: $dark-gray;


/*
* CONSENT DRAWER
*/
$consent-min-height: 85px;
$consent-border-top-width: 1px;
$consent-border-top-style: solid;
$consent-border-top-color: $brand-tertiary;
$consent-background-color: $off-white;
$consent-paragraph-margin: 4px;
$consent-paragraph-font-size: 12px;
$consent-paragraph-line-height: 12px;

/*
* FOLDER-BASED NAVIGATION (e.g. Customer Service pages)
*/
$folder-nav-active-link-color: $link-color;
$folder-nav-active-link-font-weight: bold;
$folder-nav-desktop-width: 300px;
$folder-nav-desktop-background: $white;
$folder-nav-desktop-border-right: 1px solid map-get($border, tertiary);
$folder-nav-desktop-title-size: 4; // This number should be 1-6, corresponding with h1-h6 headers
$folder-nav-desktop-title-bottom-margin: 3px;
$folder-nav-desktop-link-padding: 2px 0;
$folder-nav-desktop-nested-folder-border-color: $border-color;


/*
* BASE TABLE STYLING
*/
$table-header-value: 4;
$table-header-row-border-bottom-color: map-get($border, primary);
$table-body-row-border-bottom-color: $light-gray;


/*
* CUSTOM SCROLLBARS
*/
$custom-scrollbar-size: 8px;
$custom-scrollbar-background-color: $off-white;
$custom-scrollbar-foreground-color: $light-gray;
