@import "~client_core/helperClasses";

// Create button classes, e.g. .btn-primary
.btn {
    @include button;
    &-special {
        @include button(special);
    }
    &-tertiary {
        &:not([class*="icon"]) {
            @include icon($icon-arrow-right, after);
            &:after {
                opacity: 1;
                right: 0;
                transform: translateX(0);
            }
            &[class*="btn-"]:not(.title) {
                &:after {
                    font-size: $button-sm-icon-size;
                    transform: translateX(calc(#{$spacer} * -1)) translateY(-1px);
                }
            }
        }
    }
    &-sm {
        &.btn-special {
            @include button(special, null, null, null, small);
        }
    }
    &-lg {
        &.btn-special {
            @include button(special, null, null, null, large);
        }
    }
}
