@import "~client_core/components/footer";

footer {
    .footer-item.collapsible-sm {
        .title {
            @include banner(4);
            @include media-breakpoint-up(md) {
                @include banner(4);
            }
            @include media-breakpoint-down(sm) {
                padding: 24px 32px 24px 0;
                @include icon($icon-expand, after, 13px);
            }
            &:after {
                left: unset;
            }
        }
        &.active {
            .title {
                @include media-breakpoint-down(sm) {
                    @include icon($icon-collapse, after, 13px);
                }
            }
        }
    }
    .social-links {
        margin: 12px 0;
    }
}

.email-signup-form {
    padding-left: 8px;
    padding-right: 8px;
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 8px;
    }
    .form-control {
        color: map-get($content, primary);
        &::placeholder {
            color: map-get($content, primary);
        }
        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px map-get($bg, primary) inset;
            -webkit-text-fill-color: map-get($content, primary);
            border: none;
        }
    }
    .inline-btn {
        &:hover,
        &:focus {
            border-color: map-get($content, primary);
        }
        input {
            border-width: 0px;
        }
    }
    .email-success {
        font-weight: bold;
        font-size: $footer-signup-font-size;
        line-height: $footer-signup-line-height;
        letter-spacing: $footer-signup-letter-spacing;
    }
    .email-description {
        @include banner(3);
        color: map-get($content, inverse);
        margin-bottom: 8px;
    }
    .btn {
        @include icon($icon-arrow-right, after, 16px, map-get($content, primary));
        &::before {
            content: '';
        }
        &::after {
           transform: rotate(0deg);
        }
    }

    .email-success,
    .email-error {
        display: none;
    }

    &.submit-success {
        .email-success {
            display: block;
        }
        .email-description,
        .email-input {
            display: none;
        }
    }

    &.submit-error {
        .email-error {
            display: block;
        }
    }
}