@import "~client_core/components/productTiles";
.product-tile {
    .pdp-link {
        .link {
            @include header(7);
        }
    }
    .tile-postimage {
        justify-content: center;
    }
    .price {
        white-space: nowrap;
    }
}
.compare-child {
    .product-tile {
        .price {
            white-space: normal;
            .percent-off {
                display: block;
            }
        }
    }
}