@import "~client_core/components/header";
// Promo banner
.header-banner {
    [class^="header-banner-column-"],
    [class*=" header-banner-column-"] {
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
          margin-bottom: 0 !important;
        }
        align-items: center;
      }
}
// Left-aligned logo layout
.logo-left {
    @include media-breakpoint-up(lg) {
        .navbar.bg-inverse .navbar-nav {
            >.nav-item {
                &>.nav-link {
                    &:before {
                        transition: opacity $header-transition-speed ease-out;
                        opacity: 0;
                        left: 24px;
                    }
                }
                &>.nav-link:hover:before,
                &.show .nav-link:before {
                    opacity: 1;
                    right: 24px;
                }
            }
        }
    }
}
.header .minicart {
    .minicart-quantity {
        line-height: 18px;
        -webkit-font-smoothing: subpixel-antialiased;
    }
}