@import "~client_core/account/account";

.login-page .login-nav-tabs .nav-link {
    font: $tab-font;
    text-transform: $tab-text-transform;
    font-weight: $tab-font-weight;
    &.active {
        border-color: $tab-border-color-active;
    }
}

// Order cards
.order-card {
    .order-image-col {
        min-width: 150px;
        max-width: 150px;
    }
    .order-details-col {
        max-width: calc(100% - 150px);
        padding-left: 0;
        @include media-breakpoint-up(sm) {
            padding-left: $spacer;
        }
    }
}
