@import "~client_core/components/collapsibleItem";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            border-width: 0;
            .card-header {
                border-bottom: none;
                .title,
                .card-title,
                button.title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                    margin-bottom: 0;
                    @include headerOrBanner($collapsible-title-header-or-banner, $collapsible-title-header-value);
                    &::after {
                        left: unset;
                        right: 0;
                    }
                }
            }

        }
    }
}

.accordion {
    .card {
        border-width: 0;
        .card-header {
            &::after {
                left: unset;
                right: 0;
            }
            .card-title {    
                &::after {
                    left: unset;
                    right: 0;
                }
            }
        }
        
    }
}