@import "~client_core/product/detail";

.add-to-cart.btn.btn-primary {
    @include button(primary, null, null, null, large);
    .addtocart-sticky-bar .cart-and-ipay & {
        @include button(primary, null, null, null, large);
    }
}
.product-detail {
    .badge-product-container {
        display:unset;
    }
    
    .main-content-group {
        .card {
            .card-body {
                @include media-breakpoint-up(md) {
                    padding-left: 0;
                }
            }
        }
    }   
    
    .suggested-products-slider {
        .slider-title {
            @include banner(4);
        }
    }
}

.breadcrumb-and-wishlist {
    .product-list-enhancements-toggle-product{ 
        .product-list-enhancements-icon {
            &:before {
                font-size: $default-text-font-size;
                color: map-get($content, primary)
            }
        }
    }
}

.specification-container {
    .product-specification {
        &-value {
            @include paragraph-font-size(large);
        }
    }
}