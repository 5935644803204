@import "~client_core/components/imageTextBlock";
.image-text-block {
    .image-text-block-text {
        .primary-text {
            &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                font-size: $image-text-block-primary-font-size;
            }
            @include header(4);
        }
    }
}
