@import "~client_core/product/grid";

.refinement-bar {
    .secondary-bar {
      margin-bottom: 0;
      .secondary-bar-links {
        font-size: $paragraph-font-size-small;
        padding: $product-refinement-secondary-link-padding;
        text-decoration: underline;
      }
    }
    &:not([class*="refinement-bar-horizontal"]) {
      margin-top: 0;
      @include media-breakpoint-up(lg) {
        margin-top: $spacer-xl / 2;
      }
    }
  }
  
  .refinements {
    .refinement:not(.aside) {
      border-bottom: 1px solid map-get($border, tertiary);
      &.card {
        .card-header {
          .title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            justify-content: flex-start;
            &:after {
              opacity: 1;
            }
          }
          ~ .card-body {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
    .refinement {
      .values.content {
        margin-top: 0;
        padding-bottom: 0;
        button {
          padding: $spacer-sm 0;
        }
        a {
          text-decoration: none;
        }
        li {
          padding-bottom:0;
        }
        .values.content {
          padding-left: 24px; 
        }
        &.active {
          button.card-title {
            padding-bottom: 24px;
          }
        }
      }
      &.refinement-category {
        .values.content {
          button {
            padding: $spacer 0;
            margin-bottom: 12px;
          } 
          .values.content {
            button {
              padding: $spacer-sm 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    

    .refinement.active .values {
      button {
        span {
          text-decoration: none;
        }
        &.selected {
            border-bottom: 1px solid map-get($border, tertiary);
            width: 100%;
        }
      }
      .swatches-vertical {
        button {
          border-bottom: none;
          padding: $spacer-sm 0;
        }
      }
      .values li {
        padding-top: 0;
        padding-bottom: 0;
        button {
          border-bottom: none;
          span {
            font-size: $input-sm-font-size;
          }
        }
      }
    }
  }

  .filter-bar {
    li {
      &.filter-value {
        button {
          font-size: $product-filter-font-size !important;
        }
      }
    }
  }
  
  .refinements .collapsible-xl .title::after, 
  .collapsible-xl .title.card-title::after {
    left: $spacer-sm;
  }