// Locally stored fonts (must be placed in static/fonts folder)
// Change this one variable, the rest should be handled in client core
// For italic fonts set the object key to 'normal'
$local-fonts: (
    "Mona Sans Wide" : (
        700: "Mona-Sans-BoldWide",
        600: "Mona-Sans-SemiBoldWide"
    ),
    "Mona Sans" : (
        600: "Mona-Sans-SemiBold",
        400: "Mona-Sans-Regular"
    )
);

@import "~client_core/utilities/fonts";